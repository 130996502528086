import React from "react";
import { doesStringExists, isUndefined } from "../utils/utils";
import { Button, Typography } from "@mui/material";

const CustomButton = ({name, color, icon, onClick , type , isFullWidth, variant, labelColor}) => {

    const onButtonClick = (e) => {
        if(!isUndefined(onClick)){
            onClick(e)
        }
    }

    return (
        doesStringExists(name) &&
        <Button variant={!isUndefined(variant) ? variant :"contained"} type={type}  color={color} startIcon={!isUndefined(icon) && icon} onClick={onButtonClick} sx={{height:'50px',width:!isUndefined(isFullWidth) && isFullWidth ? '100%' : '180px'}}>
            <Typography variant="p" color={!isUndefined(labelColor)?labelColor:"#ffffff"} fontWeight={600} >{name}</Typography>
        </Button>
    )
}

export default CustomButton