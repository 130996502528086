import React, { useEffect, useState } from "react";
import { Stack, Button } from "@mui/material";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import PatientsInformationTable from "../../../../components/PatientsInformationTable/PatientsInformationTable";
import { extractKeyValuePairs, isEmpty, isNull, isUndefined } from "../../../../utils/utils";
import { addRecords } from "../../../../utils/services";
import { desiredRecordFieldsToDisplayAfterSubmission, desired_record_fields } from "../../../../utils/constants";

const AddIndividualPatientDataSection = () => {
  const [addPatientDataRows, setAddPatientDataRows] = useState([]);
  const [invalidAddRowKeys, setInvalidAddRowKeys] = useState([]);
  const [patientDataArr, setPatientDataArr] = useState([]);

  useEffect(()=>{
    handleAddRecord() // Keep one blank record for the first time when this component renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]) 

  // When you click "add record" button, add a row in which user can type calendar or int input
  const handleAddRecord = () => {
    setAddPatientDataRows([...addPatientDataRows,PatientDataTemplate(generateUniqueKey(addPatientDataRows.length))]);
  };

  async function submitRecords(records , orignalDataSet){
    const formData = new FormData();
    formData.append('records',JSON.stringify(records));
    await addRecords(formData).then((res)=>{
      if(!isNull(res.body)){
        const dataSetToDisplay = processResponseAfterSubmission(res.body.data,orignalDataSet);
        setPatientDataArr(dataSetToDisplay);
        setAddPatientDataRows([]);
      }
    })
  }

  const isValidatedData = () => {
    let invalidRows = [];

    addPatientDataRows.forEach((row) => {
      const rowData = row.data;
      const rowHasInvalidField = Object.values(rowData).some((value) => {
        const hasNoEntry = value === -1 || isEmpty(value) || isUndefined(value);
        const invalid = hasNoEntry || isNaN(value);
        return invalid;
      });

      if (rowHasInvalidField) {
        invalidRows.push(row.key);
      }
    });

    setInvalidAddRowKeys(invalidRows);
    return invalidRows.length === 0;
  };

  // Update the state when I edit a calendar or a text field in a table row
  const onAddAddPatientDataFormChange = (updatedFormData) => {
    const keyToUpdate = updatedFormData.key;
    let newAddPatientDataArr = [...addPatientDataRows];
    newAddPatientDataArr.forEach((row) => {
      if (row.key === keyToUpdate) {
        row = updatedFormData.data;
      }
    });

    setAddPatientDataRows(newAddPatientDataArr);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isValidatedData()) {
      const records = [...patientDataArr, ...addPatientDataRows];
      const filteredRecords = processDataForSubmission(records)
      submitRecords(filteredRecords , records)
    } 
  };

  function processResponseAfterSubmission(records , orignalDataSet){
    orignalDataSet.forEach((dataSet) => {
      records.forEach((record) => {
        if(dataSet.data.deidentifie_id === parseInt(record.deidentifie_id)){
          dataSet.data = extractKeyValuePairs(record,desiredRecordFieldsToDisplayAfterSubmission)
        }
      })
    })
    return orignalDataSet
  }

  function processDataForSubmission(records){
    return records.map((record)=> extractKeyValuePairs(record.data,desired_record_fields))
  }

  return (
    <form onSubmit={handleSubmit}>
        <PatientsInformationTable patientData={patientDataArr} addPatientDataRows={addPatientDataRows} onChange={onAddAddPatientDataFormChange} invalidAddRowKeys={invalidAddRowKeys}/>
        <Stack direction="row" spacing={2} sx={{ marginTop: "10px" }}>
          <Button variant="contained" color="secondary" startIcon={<AddCircleOutlinedIcon />} onClick={handleAddRecord}>Add New Record</Button>
          {addPatientDataRows.length > 0 && <Button variant="contained" type="submit"> Submit</Button>} 
        </Stack>
      </form>
  );
};

const PatientDataTemplate = (key = 0) => {
  return {
    data: {
      created_date: new Date(),
      isMultipleUpload : 0,
      deidentifie_id: -1,
      lastFillDays: -1,
      avgDurationBetweenFills: -1,
      avgQuantityPerFill: -1,
      highestQuantityDispensed: -1,
      avgDaysSupplied: -1,
      highestDaysSupplied: -1,
      highestStrength: -1,
      lowestStrength: -1,
      avgRefillsPrescribed: -1,
      highestRefillsPrescribed: -1,
      totalLastSixMonths: -1,
      totalLastTwelveMonths: -1,
      totalLastTwoYears: -1,
      status: 0,
      riskScore: 0,
    },
    key: key,
  };
};

// This function is simply for display purposes to track when I edit a textfield in a row, I will
// Know which state in the index to edit
const generateUniqueKey = (arrayLength) => {
  const currentDate = new Date();
  const timestamp = currentDate.getTime().toString();
  const uniqueKey = `${timestamp}`;
  return uniqueKey + `${arrayLength}`;
};

export default AddIndividualPatientDataSection;
