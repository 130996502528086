import { React, useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import "./login.css";
import CustomTextBox from "../../components/textbox";
import CustomButton from "../../components/button";
import { login } from "./../../utils/services";
import {
  isNull,
  isObjectEmpty,
  isObjecthasEmptyValues,
  isUndefined,
} from "../../utils/utils";
import soap_logo_img from "../../assets/images/app-logo.png";
import { validateEmail, validatePassword } from "../../utils/validation";
import { STORAGE_KEYS, saveDataLocally } from "../../utils/localStorage";

const Login = () => {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();

  const onTextChange = (formData, param, val) => {
    if (param === "username")
      setFormErrors({ ...formErrors, [param]: validateEmail(val) });
    if (param === "password")
      setFormErrors({ ...formErrors, [param]: validatePassword(val) });
    setFormData({ ...formData, [param]: val });
  };

  const isFormValidated = (data) => {
    let errors = {};
    errors.username = validateEmail(!isUndefined(data) ? data.username : "");
    errors.password = validatePassword(!isUndefined(data) ? data.password : "");
    setFormErrors(errors);
    return !isObjectEmpty(errors) && isObjecthasEmptyValues(errors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValidated(formData)) {
      const submissionData = new FormData();
      submissionData.append("username", formData.username.toLowerCase().trim());
      submissionData.append("password", formData.password.trim());

      await login(submissionData, true).then((res)=>{
        if (!isNull(res.body)) {
          saveDataLocally(STORAGE_KEYS.LOGIN_ORGANIZATION, res.body.data[0]);
          navigate("/dashboard/home", { replace: true })
        }
      })
    }
  };

  return (
    <Box className="login-container">
      <Grid container direction={"row"}>
        <Grid item md={6} />
        <Grid item md={6} padding={5}>
          <Box
            className="login-card-container"
            sx={{ backgroundColor: "#0B1E2D" }}
          >
            <img
              src={soap_logo_img}
              alt="STELLAR SOAP"
              className="img-login-card"
            />
            <Box sx={{ margin: 10 }}>
              <Typography component="h1" variant="h4" color={"white"}>
                Login to your account
              </Typography>
              <form onSubmit={handleSubmit} autoComplete="off" sx={{ mt: 1 }}>
                <Grid container direction={"column"} spacing={3}>
                  <Grid item>
                    <CustomTextBox
                      value={!isUndefined(formData) ? formData.username : ""}
                      onChange={(val) =>
                        onTextChange(formData, "username", val)
                      }
                      placeholder={"Enter your username"}
                      title={"Email ID"}
                      type={"email"}
                      error={Boolean(formErrors.username)}
                      helperText={formErrors.username}
                    />
                  </Grid>
                  <Grid item>
                    <CustomTextBox
                      value={!isUndefined(formData) ? formData.password : ""}
                      onChange={(val) =>
                        onTextChange(formData, "password", val)
                      }
                      placeholder={"Enter your password"}
                      title={"Password"}
                      type={"password"}
                      error={Boolean(formErrors.password)}
                      helperText={formErrors.password}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={<Checkbox defaultChecked color="primary" />}
                      label={
                        <span style={{ color: "white" }}>Remember me</span>
                      }
                    />
                  </Grid>
                  <Grid item>
                    <CustomButton
                      name="Login"
                      color="primary"
                      type="submit"
                      isFullWidth={true}
                      icon={<ArrowForwardIcon />}
                    />
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
