import { REDUX_ACTIONS } from "../utils/constants"

export const setAxiosResponse = (response) => {
    return {
        type : REDUX_ACTIONS.RESPONSE_HANDLER,
        axiosResponse : response
    }
}

export const setDownloadRequest = (response) => {
    return {
        type : REDUX_ACTIONS.DOWNLOAD_REQUEST,
        onDownloadRequest : response
    }
}