import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import PageNotFoundImg from "../../assets/images/no-results.png";


const PageNotFound = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <img src={PageNotFoundImg} style={{ width: "45%" }} />
        <Typography variant="h2" color="#FFFFFF" fontWeight={700}>No Results Found</Typography>
        <Typography variant="h6" color="#FFFFFF">We couldn't find what you searched for, Try searching again.</Typography>
      </Stack>
    </Box>
  )
}

export default PageNotFound;
