import "./App.css";
import Dashboard from "./modules/dashboard/dashboard";
import { Route, Routes } from "react-router-dom";
import Login from "./modules/auth/login";
import UserHistory from "./modules/dashboard/History/userHistory";
import Home from "./modules/dashboard/Home/home";
import { initiateInterceptor } from "./middleware/middleware";
import { connect, useDispatch, useSelector } from "react-redux";
import store from "./store/appStore";
import { isNull } from "./utils/utils";
import ResponseModal from "./components/responseModal";
import { setAxiosResponse } from "./store/reduxActions";
import OrganizationModule from "./modules/dashboard/Organization/organizationModule";
import UserModule from "./modules/dashboard/Users/userModule";
import UserCreation from "./modules/dashboard/Users/UserCreation/userCreation";
import OrganizationCreation from "./modules/dashboard/Organization/OrganizationCreation/organizationCreation";
import PageNotFound from "./modules/PageNotFound/pageNotFound";
import PrivateRoute from "./utils/privateRoute";

function App() {
  const dispatch = useDispatch();
  const axiosResponse = useSelector(state => state.axiosResponse)
  initiateInterceptor(dispatch).request()
  initiateInterceptor(dispatch).response()

  const handleErrorModal = () => {
    dispatch(setAxiosResponse(null))
  }

  return (
    <>
    {!isNull(axiosResponse) && <ResponseModal showModal={true} title={axiosResponse.title} message={axiosResponse.message} statusCode={axiosResponse.statusCode} onClick={handleErrorModal}/>}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path='*' element={<PrivateRoute><PageNotFound /></PrivateRoute>} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>}>
          <Route path="home" element={<Home />} />
          <Route path="history" element={<UserHistory />} />
          <Route path="organization" element={<OrganizationModule />} />
          <Route path="users" element={<UserModule />} />
          <Route path="users/create-user" element={<UserCreation />} />
          <Route path="organization/create-organization" element={<OrganizationCreation />} />
          </Route>
      </Routes>  
    </>
  );
}

export default connect(store=>store)(App);
