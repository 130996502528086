import React, { useEffect, useState } from "react";
import { Box, Typography, Stack, Grid, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, IconButton, Divider } from "@mui/material";
import { isUndefined } from "../utils/utils";
import CustomButton from "./button";
import CancelIcon from '@mui/icons-material/Cancel';
import { AppColors } from "../utils/colors";

const DecisionModal = ({ showModal,title, message, onClick , handleModalState , icon , showButtons}) => {

  const onButtonClick = () =>{
    if(!isUndefined(onClick)) {
      onClick()
    }else{
      handleModalState()
    }
  }

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose && (
          <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, color: AppColors.appColor.main }}>
            <CancelIcon fontSize='large'/>
          </IconButton>
        )}
      </DialogTitle>
    );
  }

  return (
    <Dialog open={showModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth sx={{height : '100%'}}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleModalState}>
        <Typography variant="h5" fontWeight={600} color={AppColors.appColor.main}>{title}</Typography>
      </BootstrapDialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box sx={{ textAlign: "center" }} >
            <Stack marginBottom={3} marginTop={1} justifyContent="center" alignItems="center" spacing={3}>
              {!isUndefined(icon) && <img src={icon} alt={'dialog-icon'} style={{ width: "10%", height: "auto" }}/>}
              <Typography variant="p" fontSize={19} fontWeight={500} sx={{color : AppColors.appColor.main}}>{message}</Typography>
            </Stack>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{mb:2}}>
        {
          !isUndefined(showButtons) && showButtons && 
          <Grid container alignItems="center" justifyContent="center" spacing={5}>
            <Grid item >
              <CustomButton name="Cancel" color="primary" onClick={handleModalState} />
            </Grid>
            <Grid item >
              <CustomButton name="OK" color="primary" onClick={onButtonClick} />
            </Grid>
          </Grid>
        }
      </DialogActions>
    </Dialog>
  );
};

export default DecisionModal;
