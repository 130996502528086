import { CONTACT_NUMBER_LENGTH, ZIP_CODE_LENGTH } from "./constants";
import { convertToNumber, getNumbersFromString, isEmailValidated, isEmpty, isNameValidated, isNameValidatedIncludingSpaces, isNull, isNumberGreaterThanOne, isUndefined, isWebsiteValidated } from "./utils";

export function validateFirstName(name){
    if(!isUndefined(name) && !isNull(name) && !isEmpty(name)){
        if(!isNameValidated(name)){
            return "Enter valid first name"
        }
    }else{
        return "Enter first name"
    }
    return ""
}

export function validateLastName(name,nameType){
    if(!isUndefined(name) && !isNull(name) && !isEmpty(name)){
        if(!isNameValidated(name)){
            return "Enter valid last name"
        }
    }else{
        return "Enter last name"
    }
    return ""
}

export function validateEmail(email){
    if(!isUndefined(email) && !isNull(email) && !isEmpty(email)){
        if(!isEmailValidated(email)){
            return "Enter valid email"
        }
    }else{
        return "Enter email address"
    }
    return ""
}

export function validatePassword(password){
    if(!isUndefined(password) && !isNull(password) && isEmpty(password)){
            return "Enter Password"
    }
    return ""
}

export function validateConfirmPassword(confirmPassword){
    if(!isUndefined(confirmPassword) && !isNull(confirmPassword) && isEmpty(confirmPassword)){
        return "Enter Confirm Password"
    }
    return ""
}

export function matchPasswords(password , confirmPassword){
    if(password !== confirmPassword){
        return "Passwords do not match"
    }
    return ""
}

export function validateContactNumber(contact) {
  if (!isUndefined(contact) && !isNull(contact) && !isEmpty(contact)) {
    if (getNumbersFromString(contact).length !== CONTACT_NUMBER_LENGTH) {
      return "Enter valid phone number"
    }
  }else{
    return "Enter phone number"
  }
  return ""
}

export function validateUserRole(role){
  if (isNull(role)) {
    return "Select user role";
  }
  return "";
}

export function validateOrganization(org){
  if (isNull(org)) {
    return "Select organization";
  }
  return "";
}

export function validateOrganizationName(orgName) {
  if (!isUndefined(orgName) && !isNull(orgName) && !isEmpty(orgName)) {
    if (!isNameValidated(orgName)) {
      return "Enter valid organization name"
    }
  } else {
    return "Enter organization name"
  }
  return ""
};

export function validateAddress(address) {
  if (isEmpty(address)) {
    return "Enter your address"
  }
  return ""
}

export function validatePostalCode(postalCode) {
  if (!isUndefined(postalCode) && !isNull(postalCode) && !isEmpty(postalCode)) {
    if (getNumbersFromString(postalCode).length !== ZIP_CODE_LENGTH) {
      return "Zip code contain 5 numbers only"
    }
  } else {
    return "Enter zip code"
  }
  return ""
};

export function validateWebsiteURL(url) {
  if (!isUndefined(url) && !isNull(url) && !isEmpty(url)) {
    if (!isWebsiteValidated(url)) {
      return "Enter valid website URL"
    }
  } else {
    return "Enter website URL"
  }
  return ""
}

export function validateDescription(desc) {
  if (isEmpty(desc)) {
    return "Enter your description"
  }
  return ""
}

export function validateSelectCity(city){
  if (isNull(city)) {
    return "Select a city";
  }
  return "";
}

export function validateSelectState(state){
  if (isNull(state)) {
    return "Select a state";
  }
  return "";
}

export function validateSelectCountry(country){
  if (isNull(country)) {
    return "Select a country";
  }
  return "";
}

