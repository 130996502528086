export const BASE_API_URL = process.env.REACT_APP_API_KEY;
export const WEB_DOMAIN = process.env.REACT_APP_WEB_DOMAIN;

export const URL = {
  "LOGIN": BASE_API_URL + '/login',
  "FETCH_RECORDS": BASE_API_URL + '/get_records',
  "ADD_INDIVIDUAL_RECORDS": BASE_API_URL + '/submit_individual_records',
  "ADD_MULTIPLE_RECORDS": BASE_API_URL + '/submit_bulk_record',
  "DELETE_RECORDS": BASE_API_URL + '/delete_records',
  "FETCH_USER_LIST": BASE_API_URL + '/user_list',
  "FETCH_ORG_LIST": BASE_API_URL + '/org_list',
  "FETCH_ROLE_LIST": BASE_API_URL + '/role_list',
  "ADD_USER": BASE_API_URL + '/add_user',
  "UPDATE_USER": BASE_API_URL + '/update_user',
  "ADD_ORGANIZATION": BASE_API_URL + '/addOrg',
  "FETCH_COUNTRIES": BASE_API_URL + '/get_countries',
  "FETCH_STATES": BASE_API_URL + '/get_states',
  "FETCH_CITIES": BASE_API_URL + '/get_cities',
  "DELETE_USER": BASE_API_URL + '/delete_user',
  "DELETE_ORGANIZATION": BASE_API_URL + '/delete_organization',
  "FETCH_RANGE_FIELD_MAX_MIN_VALUES": BASE_API_URL + '/get_maxmin_value',
}