import React, { useState, useContext, useEffect } from "react";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Stack, Popover } from "@mui/material";
import ToolBarIconButton from "../toolbarIconButton/toolbarIconButton";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import { HistoryFilterContext } from "../../modules/dashboard/historyFilterContext";
import { addTime } from './../../utils/utils'

// 2 EXPORTS IN THIS FILE:
// <DateRangePicker />
// And
/* 
<CustomDatePicker
 selectedDate 
 onChange  
 text(optional) 
 disableFuture(optional) 
 height(optional)  
 startIcon(optional) 
 endIcon(optional) 
 note: to have no icon, pass <></>
 />
*/

export const DateRangePicker = ({ height = 40 }) => {
  const { filters, setFilters } = useContext(HistoryFilterContext)

  // Helper function to convert date string to Date object or null
  // Should this go in utils?
  const getDateValue = (dateStr) => {

    const date = new Date(dateStr);
    if (isNaN(date)) {
      return null;
    }
    return date;
  };

  // Initialize states for startDate and endDate
  const [startDate, setStartDate] = useState(getDateValue(filters['created_date_from_date']));
  const [endDate, setEndDate] = useState(getDateValue(filters['created_date_to_date']));
  const [displayedEndDate, setDisplayedEndDate] = useState(getDateValue(filters['created_date_to_date']));


  // Use useEffect to listen for changes in filters
  useEffect(() => {
    setStartDate(getDateValue(filters['created_date_from_date']));
    setEndDate(getDateValue(filters['created_date_to_date']));

    // On reset: displayed end date shall be null
    if (filters['created_date_to_date'] === undefined) {
      setDisplayedEndDate(null);
    }
  }, [filters]);


  const handleStartDateChange = (date) => {
    let newFilters = { ...filters, "created_date_from_date": date.toISOString() }
    setFilters(newFilters);
  };

  const handleEndDateChange = (date) => {
    // Set the displayed end date to the original selected date
    setDisplayedEndDate(date);

    // Adjust the date for backend processing
    let updatedDate = addTime(date, 23, 59, 59);

    let newFilters = { ...filters, "created_date_to_date": updatedDate.toISOString() }
    setFilters(newFilters);
  };


  return (

    <Stack direction="row" spacing={0}>
      <CustomDatePicker
        height={height}
        text="From Date"
        selectedDate={startDate}
        onChange={handleStartDateChange}
        disableFuture
        maxDate={endDate}
      />
      <CustomDatePicker
        height={height}
        text="To Date"
        selectedDate={displayedEndDate}
        onChange={handleEndDateChange}
        disableFuture
        minDate={startDate}
      />
    </Stack>

  );
};

export const CustomDatePicker = ({
  text = "Date",
  selectedDate,
  onChange,
  disableFuture = false,
  height,
  startIcon = <CalendarMonthOutlinedIcon />,
  endIcon = <UnfoldMoreOutlinedIcon />,
  minDate,
  maxDate
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ToolBarIconButton
          toolBarStartIcon={startIcon}
          toolBarTitle={selectedDate ? selectedDate.toLocaleDateString() : text}
          toolBarEndIcon={endIcon}
          onClick={handleClick}
          height={height}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <DateCalendar
            minDate={minDate}
            maxDate={maxDate}
            disableFuture={disableFuture}
            value={selectedDate}
            onChange={(newDate) => {
              onChange(newDate);
              handleClose();
            }}
          />
        </Popover>
      </LocalizationProvider>
    </>
  );
};
