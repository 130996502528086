import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Grid, Stack } from '@mui/material';

import CustomTextBox from '../../../../components/textbox';
import CustomAutocomplete from '../../../../components/AutoComplete/autoComplete';
import { ACTION_STATUS, USER_DETAILS } from '../../../../utils/constants';
import { validateContactNumber, validateEmail, validateFirstName, validateLastName, validateOrganization, validateUserRole } from '../../../../utils/validation';
import PhoneNumber from '../../../../components/PhoneNumberInput/phoneNumber';
import { isArrayEmpty, isNull, isObjectEmpty, isObjecthasEmptyValues, isSuperAdmin, isUndefined } from '../../../../utils/utils';
import CustomButton from '../../../../components/button';
import './userCreation.css';
import { addUser, getOrgList, getUserRoleList, updateUser } from '../../../../utils/services';
import { STORAGE_KEYS, getDataLocally } from '../../../../utils/localStorage';
import Loader from '../../../../components/Loader/loader';
import ResponseModal from '../../../../components/responseModal';


const UserCreation = () => {

  const initialUserData = {
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    organization: null,
    userRole: null,
  };

  const navigate = useNavigate();
  const { state } = useLocation();

  const [userData, setUserData] = useState(initialUserData);
  const [userFormError, setUserFormError] = useState({});
  const [orgListData, setOrgListData] = useState([]);
  const [userRoleData, setUserRoleData] = useState([]);
  const [displayUserStatus, setDisplayUserStatus] = useState();
  const [showLoader, setLoader] = useState(false);
  const [successResponse, setSuccessResponse] = useState({});
  const [showModal, setShowModal] = useState(false);

  const isUserDataNotNull = !isNull(state) && !isNull(state.userData);
  const isViewUser  = isUserDataNotNull && state.displayStatus === ACTION_STATUS.VIEW; //view status value 1
  const getLoggedInOrganization = getDataLocally(STORAGE_KEYS.LOGIN_ORGANIZATION);

  const handleSuccessModal = () => {
    setShowModal(false);
    navigateToUserList();
  };

  const handleChange = (param, val) => {
    console.log(val.org_id, val.role_id, val)
    const error = validatedForm(param, val);
    setUserData({ ...userData, [param]: val });
    setUserFormError({ ...userFormError, [param]: error })
  }

  const validatedForm = (param, value) => {
    const validationFunctions = {
      [USER_DETAILS.FIRST_NAME]: validateFirstName,
      [USER_DETAILS.LAST_NAME]: validateLastName,
      [USER_DETAILS.EMAIL_ID]: validateEmail,
      [USER_DETAILS.PHONE_NUMBER]: validateContactNumber,
    }
    
    const validationFunction = validationFunctions[param];
    if (validationFunction) return validationFunction(value);
  };


  const isFormValidated = (data) => {
    let errors = {};
    errors.firstName = validateFirstName(data.firstName);
    errors.lastName = validateLastName(data.lastName);
    errors.emailId = validateEmail(data.emailId);
    errors.phoneNumber = validateContactNumber(data.phoneNumber);
    errors.organization = validateOrganization(data.organization);
    errors.userRole = validateUserRole(data.userRole);
    setUserFormError(errors);
    return !isObjectEmpty(errors) && isObjecthasEmptyValues(errors);
  };

  const navigateToUserList = () => { navigate("/dashboard/users") }

  const handleDisplayStatus = () => {setDisplayUserStatus(ACTION_STATUS.EDIT);};

  const getRoleList = async (e) => {
    await getUserRoleList().then((res) => {
      if (!isNull(res.body)) {
        setUserRoleData(res.body.data);
        }
    });
  }

  async function getOrgListData() {
    let params = {
      org_id: getLoggedInOrganization.org_id,
      display_type: isSuperAdmin() ? 0 : 1,
      isMaster: true
    }
    await getOrgList(params).then((res) => {
      if (!isNull(res.body)) {
        setOrgListData(res.body.data);
      }
    })
  }
  
  const submitUserForm = async (form) => {
    setLoader(true);
    await addUser(form).then((res) => {
      if (!isNull(res.body)) {
        setShowModal(true);
        const response = {
          statusCode: res.statusCode,
          message: res.statusMessage
        }
        setSuccessResponse(response);
      }
      setLoader(false);
    }).catch(() => { setLoader(false); })
  };

  const updateUserForm = async (form) => {
    setLoader(true);
    await updateUser(form).then((res) => {
      if (!isNull(res.body)) {
        setShowModal(true);
        const response = {
          statusCode: res.statusCode,
          message: res.statusMessage
        }
        setSuccessResponse(response);
      }
      setLoader(false);
    }).catch((error) => {setLoader(false);})
  };

  const handleSubmit = () => {
    if (isFormValidated(userData)) {
      const formData = new FormData();
      formData.append("first_name", userData.firstName);
      formData.append("last_name", userData.lastName);
      formData.append("user_phone", "+1" + userData.phoneNumber.replace(/-/g, ''));
      formData.append("org_id", userData.organization.org_id);
      formData.append("role_id",userData.userRole.role_id);
      if (isUserDataNotNull) {
        formData.append("user_id", state.userData.user_id);
        updateUserForm(formData);
      } else {
        formData.append("email_id", userData.emailId);
        submitUserForm(formData);
      }
    }
  }

  useEffect(() => {
    if (isUserDataNotNull) {
      const newDisplayStatus = !isUndefined(displayUserStatus) ? displayUserStatus : state.displayStatus ;
      state.displayStatus = newDisplayStatus;
      const initialUserData = {
        firstName: state.userData.first_name,
        lastName: state.userData.last_name,
        emailId: state.userData.email_id,
        phoneNumber:state.userData.user_phone.replace('+1', ''),
        organization: state.userData || null,
        userRole:  state.userData || null,
      };
      setUserData(initialUserData);
      setDisplayUserStatus(newDisplayStatus); // Update the edit status of the user actions;
    }
    getOrgListData();
    getRoleList();
  }, [displayUserStatus]);

  console.log(!isNull(userData.userRole)&& userData.userRole.role_id)

  return (
    <Box component="div" className='user-creation-container'>
      <Loader show={showLoader} />
      {showModal === true && <ResponseModal showModal={showModal} message={successResponse.message} statusCode={successResponse.statusCode} onClick={handleSuccessModal}/>}
      {isViewUser &&
        <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}        
      >
        <CustomButton type="button" color="secondary" name="Go Back"  onClick={navigateToUserList}/>
        <CustomButton type="button" color="primary" name="Edit" onClick={handleDisplayStatus} />
      </Stack>
      }
    <Box component="div" className='user-creation-content' sx={{mt: isViewUser ? 2 : 4}}>
      <Grid container direction={"column"} spacing={2} sx={{ width: { md: "40%" } }} >
        <Grid item >
          <CustomTextBox
            value={userData.firstName}
            onChange={(val) => handleChange(USER_DETAILS.FIRST_NAME, val)}
            customLabelName="user-label-name"
            customClassName={"user-textbox"}
            placeholder={"Enter your first name"}
            title={"First Name"}
            type={"text"}
            error={Boolean(userFormError.firstName)}
            helperText={userFormError.firstName}
            disabled={isViewUser}
          />
        </Grid>
        <Grid item>
          <CustomTextBox
            value={userData.lastName}
            onChange={(val) => handleChange(USER_DETAILS.LAST_NAME, val)}
            customLabelName="user-label-name"
            customClassName={"user-textbox"}
            placeholder={"Enter your last name"}
            title={"Last Name"}
            type={"text"}
            error={Boolean(userFormError.lastName)}
            helperText={userFormError.lastName}
            disabled={isViewUser}
          />
        </Grid>
        <Grid item>
          <CustomTextBox
            value={userData.emailId}
            onChange={(val) => handleChange(USER_DETAILS.EMAIL_ID, val)}
            customLabelName="user-label-name"
            customClassName={"user-textbox"}
            placeholder={"Enter your email address"}
            title={"Email ID"}
            type={"text"}
            error={Boolean(userFormError.emailId)}
            helperText={userFormError.emailId}
            disabled={isUserDataNotNull}
          />
        </Grid>
        <Grid item>
          <PhoneNumber
            value={userData.phoneNumber}
            onChange={(val) => handleChange(USER_DETAILS.PHONE_NUMBER, val)}
            customLabelName="user-label-name"
            placeholder={"Enter your phone number"}
            label={"Contact"}
            format={"###-###-####"}
            maxLength={13}
            fullWidth={true}
            error={Boolean(userFormError.phoneNumber)}
            helperText={userFormError.phoneNumber}
            disabled={isViewUser}
          />
        </Grid>
        <Grid item>
          <CustomAutocomplete
            disableClearable={true}
            options={orgListData}
            getOptionLabel={(option) => option.org_name}
            value={userData.organization ? orgListData.find(org => org.org_id === userData.organization.org_id) : null}
            onChange={(event,val) => handleChange(USER_DETAILS.ORGANIZATION, val)}
            customLabelName="user-label-name"
            fullWidth={true}
            label="Select Organization"
            placeholder={"select a organization"}
            error={Boolean(userFormError.organization)}
            helperText={userFormError.organization}
            disabled={isViewUser}
          />
        </Grid>
        <Grid item>
          <CustomAutocomplete
            disableClearable={true}
            options={userRoleData}
            customLabelName="user-label-name"
            getOptionLabel={(option) => option.role_name}
            value={userData.userRole ? userRoleData.find(role => role.role_id === userData.userRole.role_id) : null}
            onChange={(event,val) => handleChange(USER_DETAILS.USER_ROLE, val)}
            fullWidth={true}
            label="User Role"
            placeholder={"select a user role"}
            error={Boolean(userFormError.userRole)}
            helperText={userFormError.userRole}
            disabled={isViewUser}
          />
        </Grid>
        </Grid>
      </Box>
      {!isViewUser &&
        <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={8}
      >
        <CustomButton type="button" variant="outlined" labelColor="primary" name="Go Back" onClick={navigateToUserList} />
        <CustomButton type="submit" color="primary" name={isUserDataNotNull ? "Update": "Submit"}  onClick={handleSubmit}/>
      </Stack>
      }
    </Box>
  )
}

export default UserCreation;
