import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { StyledTextField } from "./TableTextField";

const StyledDateTextField = styled(StyledTextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    width: "80px", // Override the width to fit content 'fit-content'
  },
}));

const CustomDateInput = ({ selectedDateTime, onChange, disabled = false }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        disabled={disabled}
        onChange={onChange}
        formatDensity="dense"
        value={selectedDateTime}
        slots={{
          textField: StyledDateTextField,
        }}
        format={'MM-dd-yyyy'}
        disableOpenPicker
      />
    </LocalizationProvider>
  );
};

export default CustomDateInput;
