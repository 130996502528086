import { isNull } from "./utils";

export const STORAGE_KEYS = {
    LOGIN_ORGANIZATION: 'login_organization',
}

export function saveDataLocally(key, data) {
    localStorage.setItem(key,JSON.stringify(data))
}

export function getDataLocally(key){
    return !isNull(localStorage.getItem(key)) ? JSON.parse(localStorage.getItem(key)) : null;
}

export const clearUserCredentials = () => {
  localStorage.clear();
};