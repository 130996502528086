import { AppColors } from "./colors";
import FolderIcon from '@mui/icons-material/Folder';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import { TextField } from "@mui/material";
import styled from "styled-components";

export const PASSWORD_LENGTH = 8;
export const LOGO_NAME_LENGTH = 50;
export const ZIP_CODE_LENGTH = 5;
export const CONTACT_NUMBER_LENGTH = 10;

export const Bytes = "Bytes";
export const MegaByte = "MB";
export const KiloByte = "KB";
export const GigaByte = "GB";

export const ByteValue = 100000; // 100 KB Value
export const imageSizeLimit = 100; //in KB
export const imageResolutionLimit = 150; //150x150
export const TABLE_HEADER_FONT_SIZE = 14;

export const MIN_RANGE_VALUE = 0;
export const MAX_RANGE_VALUE = 100;

export const REDUX_ACTIONS = {
  TOGGLE_SIDEBAR: 'toggle_sidebar',
  RESPONSE_HANDLER: 'response_handler',
  DOWNLOAD_REQUEST: 'download_request',
}

export const BASE64_PREFIX =
  "data:image/png;image/jpg;image/jpeg;image/svg+xml;base64,";

export const ACCEPTING_MIME_TYPES = ["image/jpeg", "image/png", "image/jpg"];

export const DRAG_TYPES = {
  SURVEY_BUILDER: "text/html",
};

export const PROSPECT_STATUS = {
  REQUESTED: "Requested",
  DELETED: "Deleted",
};

export const contactInformation = {
  CONTACT_NUMBER: "",
  CONTACT_EMAIL: "support@stellarehs.com",
};

export const NETWORK_STATUS_CODES = {
  SUCCESS: 200,
  SESSION_EXPIRE: 401,
  LINK_EXPIRE: 422,
  BAD_REQUEST: 400,
  EMAIL_ALREADY_EXISTS: 409,
  UPDATE_SUCCESS: 201,
  ACCOUNT_DEACTIVATION: 403,
  SERVICE_UNAVAILABLE: 503,
};

export const VALIDATION_STATUS = {
  SUCCESS: "success",
  ERROR: "error",
};

export const USER_ROLES = {
  SUPER_ADMIN: 1,
};

export const ACTION_STATUS = {
  CREATION: 0,
  VIEW: 1,
  EDIT: 2,
};

export const PHONE_NUMBER_REGEX = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

export const UPLOAD_TYPE_STATUS = {
  'individual' : <div title="Individual Upload"><FolderIcon sx={{color : AppColors.appColor.main}}/></div>,
  'multiple' : <div title="Multiple Upload"><FolderCopyIcon sx={{color : AppColors.appColor.main}}/></div>
}

// Map the variable that maps whichever value you push using the API call, to its associated Header to display
// In the future, if suppose client wants to add another field (such as totalLastThreeMonths), 
// then developer can do:
// "totalLastThreeMonths: "Total Last\n3 Months"
// and it will dynamically be added

// Put the \n where you want to add a new line inside the table (does not apply to filters)
export const recordFieldMapping = {
  "created_date": "Date",
  "isMultipleUpload": "Upload\nType",
  "deidentifie_id": "DID",
  "lastFillDays": "Days \nSince\nLast Fill",
  "avgDurationBetweenFills": "Average \nDuration\nBetween Fills",
  "avgQuantityPerFill": "Average \nQuantity\nPer Fill",
  "highestQuantityDispensed": "Highest \nQuantity\nDispensed",
  "avgDaysSupplied": "Average \nDays\nSupplied",
  "highestDaysSupplied": "Highest \nDays\nSupplied",
  "highestStrength": "Highest\nStrength",
  "lowestStrength": "Lowest\nStrength",
  "avgRefillsPrescribed": "Average Number \nOf Refills \nPrescribed",
  "highestRefillsPrescribed": "Highest Number \nOf Refills \nPrescribed",
  "totalLastSixMonths": "Total \nLast\n6 Months",
  "totalLastTwelveMonths": "Total \nLast\n12 Months",
  "totalLastTwoYears": "Total \nLast\n24 Months"
};

export const patientRecordTableHeaders = Object.values(recordFieldMapping);
export const desired_record_fields = Object.keys(recordFieldMapping);


export const desiredRecordFieldsToDisplayAfterSubmission = [
  ...desired_record_fields,
  "status",
  "riskScore",
  "status_message"
];

export const USER_TABLE_HEADER_COLUMNS = [
  { id: 'firstName', label: 'First Name'},
  { id: 'lastName', label: 'Last Name'},
  { id: 'emailId', label: 'Email' },
  { id: 'phoneNumber', label: 'Phone Number'},
  { id: 'action', label: 'Action'},
];

export const USER_DETAILS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL_ID: 'emailId',
  PHONE_NUMBER: 'phoneNumber',
  ORGANIZATION: 'organization',
  USER_ROLE: 'userRole'
}

export const ORGANIZATION_DETAILS = {
  ORGANIZATION_NAME: 'organizationName',
  ADDRESS: 'address',
  ORG_EMAIL: 'orgEmail',
  CONTACT_NUMBER: 'contactNumber',
  WEBSITE_URL: 'websiteUrl',
  DESCRIPTION: 'description',
  POSTAL_CODE: 'postalCode',
  SELECT_CITY: 'selectCity',
  SELECT_STATE: 'selectState',
  SELECT_COUNTRY: 'selectCountry',
}

export const ORGANIZATION_TABLE_HEADER_COLUMNS = [
  { id: 'orgName', label: 'Organization Name' },
  { id: 'orgEmail', label: 'Organization Email' },
  { id: 'orgDescription', label: 'Description' },
  { id: 'orgAddress', label: 'Address' },
  { id: 'contact', label: 'Contact' },
  { id: 'orgWebsite', label: 'Website' },
  { id: 'action', label: 'Action' },
];

export const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    color: "#201f1fcc",
    "& fieldset": {
      borderColor: AppColors.appColor.main,
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor:AppColors.appColor.main,
    },
  },
});