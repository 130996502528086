import React from 'react';
import { Autocomplete } from '@mui/material';
import CustomInputLabel from '../InputLabel/customInputLabel';
import { StyledTextField } from '../../utils/constants';


const CustomAutocomplete = (props) => {
  const { options, getOptionLabel, value, onChange, fullWidth, label, id, customLabelName, placeholder, error, helperText, disabled, disableClearable
  } = props;
  return (
    <>
      <CustomInputLabel label={label} customClassName={customLabelName} />
      <Autocomplete
        sx={{ mb: 1 }}
        // disablePortal
        disableClearable={disableClearable}
        id={id}
        options={options}
        getOptionLabel={getOptionLabel}
        value={value}
        onChange={onChange}
        fullWidth={fullWidth}
        disabled={disabled}
        renderInput={
          (params) =>
            <StyledTextField
              {...params}
              placeholder={placeholder}
              error={error}
              helperText={helperText}
            />
        }
      />
    </>
  )
}

export default CustomAutocomplete;
