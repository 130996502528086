import React, { useState } from "react";
import { Stack, Slider, Box, styled, Typography } from "@mui/material";
import { recordFieldMapping } from "../../utils/constants";
import { AppColors } from "../../utils/colors";

// RANGE SLIDER COMPONENT TO BE USED WITHIN THE FILTER OPTIONS CARD
const RangeSlider = ({ recordKey, localFilters, setLocalFilters, maxMinRangeValues,maxMinSliderValues }) => {
  // Instead of value and set value, I will have a context creating all the filters

  const recordKeyStr = `${recordKey}`
  const maxMinValueOfSlider = maxMinSliderValues[recordKeyStr];
  const maxMinValueOfRange = maxMinRangeValues[recordKeyStr];
  const [value, setValue] = useState([maxMinValueOfRange[1], maxMinValueOfRange[0]]);

  const handleChange = (event, newvalue) => {
    setValue(newvalue);
    setLocalFilters({
      ...localFilters,
      [recordKeyStr]: `${newvalue[0]}, ${newvalue[1]}`
    })
  };
  return (
    <>
      <Typography variant="p" fontWeight={600}>{recordFieldMapping[recordKey]}</Typography>
      <Stack direction="row" alignItems="center" spacing={3} mt={2}>
        <ValDisplayBox>{value[0]}</ValDisplayBox>
        <Slider value={value} step={1} onChange={handleChange} min={maxMinValueOfSlider[1]} max={maxMinValueOfSlider[0]} disableSwap sx={{ color: AppColors.appColor.secondary, margin: "0px 10px", }} />
        <ValDisplayBox>{value[1]}</ValDisplayBox>
      </Stack>
    </>
  );
};

const ValDisplayBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey["100"],
  border: "1px solid",
  borderColor: AppColors.grey.shade,
  borderRadius: "5px",
  margin: "2px 8px",
  padding: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100px",
  fontWeight: 600
}));
export default RangeSlider;
