import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Stack, Typography } from "@mui/material";
import { AppColors } from "../../utils/colors";
import DashedBox from "../DashedBox";
import { getRiskColorByLabel, isEmpty, isUndefined } from "../../utils/utils";

const CircularRiskScore = ({ riskScore , statusMessage }) => {
  const [riskScoreValue , setRiskScoreValue ] = useState(0)

  useEffect(()=>{
    setTimeout(() => setRiskScoreValue(riskScore), 1000)
  },[])
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={1} margin={1}>
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" value={riskScore} thickness={6} size={90} sx={{ color: AppColors.appColor.main}}/>
        <Box sx={{top: 0, left: 0, bottom: 0, right: 0, position: "absolute", display: "flex", alignItems: "center", justifyContent: "center",}}>
          <Typography variant="p" fontSize={18} fontWeight={600}>{riskScore}%</Typography>
        </Box>
      </Box>
      {!isUndefined(statusMessage) && !isEmpty(statusMessage) && <DashedBox text={statusMessage} color={getRiskColorByLabel(statusMessage)} padding={3}/>}
    </Stack>
  );
};

export default CircularRiskScore;
