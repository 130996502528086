import React from "react";
import { Box, Stack, Grid, IconButton, Paper, styled, Typography } from "@mui/material";
import { AppColors } from "../../../utils/colors";
import CircularRiskScore from "../../CircularProgress/CircularRiskScore";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloseIcon from "@mui/icons-material/Close";
import { recordFieldMapping } from "../../../utils/constants";
import { isUndefined } from "../../../utils/utils";

const ViewDataBackdrop = ({ patientData, closeAction }) => {
  // created_date is from history page
  // dateTime is from home page
  const displayDate = patientData.created_date
    ? new Date(patientData.created_date).toLocaleString()
    : new Date(patientData.dateTime).toLocaleString();

  // These represent the rendering of the entire collection of grey boxes including the title of the table entry
  // As well as its corresponding value.
  const RenderDataBoxes = () => {
    const excludedKeys = new Set([
      "record_id",
      "created_date",
      "isMultipleUpload",
    ]);

    // In the figma, these keys had more width
    const widerLengthKeys = new Set([
      'avgRefillsPrescribed',
      'highestRefillsPrescribed'
    ])

    const DataItemKeys = Object.keys(patientData).filter((key) => {
      return !excludedKeys.has(key);
    });

    const DataItemBoxes = DataItemKeys.map((key, index) => {
      return (
        <DataItemBox
          key={index}
          title={recordFieldMapping[key]}
          value={patientData[key]}
          cols={widerLengthKeys.has(key) ? 6 : 4}
        // In the figma design, two of the items took up more width
        />
      );
    });

    return DataItemBoxes;
  };

  return (
    <Box
      sx={{
        overflow: "auto",
        boxSizing: "border-box",
        position: "absolute",
        width: "45%",
        right: "1px",
        height: "100%",
        backgroundColor: "white",
        fontWeight: "1",
      }}
    >
      <Header closeAction={closeAction} />
      <Grid container spacing={4} sx={{ padding: "20px" }}>
        <Grid container item xs={8} spacing={3}>
          <Grid item xs={12}>
            <TopLeft>
              <LightText>Created Date and Time</LightText>
              <b>{displayDate}</b>
            </TopLeft>
          </Grid>
          <Grid item xs={6}>
            <TopLeft>
              <LightText>Upload type</LightText>
              <b>{!isUndefined(patientData.isMultipleUpload) && patientData.isMultipleUpload === 1 ? 'Multiple Upload' : 'Individual Upload'}</b>
            </TopLeft>
          </Grid>
          <Grid item xs={6}>
            <TopLeft>
              <LightText>File Name</LightText>
              <b>Stellar.xlsx</b>
            </TopLeft>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Stack
            component={Paper}
            direction="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            sx={{
              height: "100%",
              padding: "2px 4px",
            }}
            elevation={4}
          >
            <b>Risk Score</b>
            <b>Probabilities in %</b>
            <CircularRiskScore riskScore={patientData.riskScore} statusMessage={patientData.status_message}/>
          </Stack>
        </Grid>
        <RenderDataBoxes />
      </Grid>
    </Box>
  );
};

const DataItemBox = ({ title, value = "value", cols = 4 }) => {
  if (!title) return null;
  return (
    <Grid item xs={cols} >
      <TopLeft>
        <Typography variant="p" fontSize={14} >{title}</Typography>
        <Box
          sx={{ border: `1px solid ${AppColors.grey.light}`, borderRadius: "5px", width: "100%", display: "flex", justifyContent: "center", padding: "3px", margin : '5px',
            height : '40px', backgroundColor: AppColors.grey.light,textAlign : 'center',
          }}>
          <Typography variant="p" align="center" padding={1} fontWeight={600}>{value}</Typography>
        </Box>
      </TopLeft>
    </Grid>
  );
};

const Header = ({ closeAction }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ color: AppColors.grey.primary, backgroundColor: AppColors.grey.dark, padding: "10px",}}>
      <Box>Create Individual Patient Data Information</Box>
      <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
        <IconButton size="small" style={{ backgroundColor: AppColors.appColor.secondary, borderRadius: "5px", color: AppColors.grey.primary,}}>
          <SaveAltIcon />
        </IconButton>
        <IconButton size="small" style={{ backgroundColor: AppColors.error.main, borderRadius: "5px", color: AppColors.grey.primary,}} onClick={closeAction}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

const TopLeft = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  flexWrap: "wrap",
});

const LightText = styled("span")({
  color: AppColors.grey.medium,
});

export default ViewDataBackdrop;
