import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#004980',
    },
    secondary: {
      main: '#3797EF',
    },
    tertiary: {
      main : '#66AFE9'
    },
    warning : {
      main : '#ffc107'
    },
    success: {
      main : "#388e3c"
    },
    grey:{
      main : '#388e3c'
    }
  },
});

export default theme;