import React, { useEffect, useState } from "react";
import { Grid, Backdrop, Paper, Table, Checkbox, TableContainer, Box, TableHead, TableBody, TableRow, IconButton, Stack, Typography, } from "@mui/material";
import { AppColors } from "./../../utils/colors";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TableTextField from "./subcomponents/TableTextField";
import TableDateTimePicker from "./subcomponents/TableDateTimePicker";
import UploadStatusBarSlider from "../UploadStatusBarSlider";
import { TABLE_HEADER_FONT_SIZE, UPLOAD_TYPE_STATUS, patientRecordTableHeaders } from "../../utils/constants";
import ViewDataBackdrop from "./subcomponents/ViewDataBackdrop";
import FixedHeightTableCell from "./subcomponents/FixedHeightTableCell";
import { getNiceDate, getRiskColorByLabel, isArrayEmpty, isEmpty, isUndefined } from "../../utils/utils";
import { desired_record_fields } from "../../utils/constants";
import DashedBox from "../DashedBox";
import { headerOptionsRoutes } from "../../utils/routes";
import { useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { setDownloadRequest } from "../../store/reduxActions";
import store from "../../store/appStore";
import { FamilyRestroomRounded } from "@mui/icons-material";
import FolderIcon from '@mui/icons-material/Folder';
import { deleteRecords } from "../../utils/services";
import Loader from "../Loader/loader";

const PatientsInformationTable = ({ patientData, addPatientDataRows = [], onChange, invalidAddRowKeys, }) => {
  const [records, setRecords] = useState([])

  useEffect(() => {
    setRecords(patientData)
  }, [patientData])
  const onDeleted = (selectedRecord) => {
    var filteredPlayers = patientData.filter((record) => record.deidentifie_id !== selectedRecord.deidentifie_id)
    setRecords(filteredPlayers)
    console.log(selectedRecord)
  }
  return (
    <Grid container >
      <Grid item md={10}>
        <LeftSideTable patientData={records} addPatientDataRows={addPatientDataRows} onChange={onChange} invalidAddRowKeys={invalidAddRowKeys} />
      </Grid>
      <Grid item md={2}>
        <RightSideTable patientData={records} addPatientDataRows={addPatientDataRows} onRecordDeleted={onDeleted} />
      </Grid>
    </Grid>
  );
};

const LeftSideTable = ({ patientData, addPatientDataRows, onChange, invalidAddRowKeys = [], }) => {
  const { pathname } = useLocation()
  const [selectedRecords, setSelectedRecords] = useState([]);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(setDownloadRequest(selectedRecords))
  }, [selectedRecords])

  const handleRecordSelection = e => {
    const { checked } = e.target;
    const value = JSON.parse(e.target.value);
    if (checked) {
      setSelectedRecords(prev => [...prev, value]);
    } else {
      setSelectedRecords(prev => prev.filter(x => parseInt(x.deidentifie_id) !== parseInt(value.deidentifie_id)));
    }
  }

  const rowDataSection = patientData.map((data, index) => {
    return <PatientDataRow rowData={data} key={index} onRecordSelection={handleRecordSelection} />
  });

  const addRecordSection = addPatientDataRows.map((formData, index) => {
    return <AddPatientDataRow formData={formData} key={index} onChange={onChange} invalid={invalidAddRowKeys.some((key) => formData.key === key)} />
  });

  return (
    <Box sx={{ overflowY: "auto" }}>
      <TableContainer component={Paper} sx={{ borderStartStartRadius: '20px' }}>
        <Table size="small" >
          <TableHead sx={{ backgroundColor: AppColors.grey.dark, color: "white", height: '80px' }}>
            <TableRow>
              {headerOptionsRoutes.includes(pathname) && <FixedHeightTableCell />}
              {patientRecordTableHeaders.map((header, index) => {
                return (
                  <FixedHeightTableCell key={index + 1} sx={{ color: "white", fontWeight: 500, fontSize: "14px" }}>
                    {header.split('\n').map((headerLine, key) => {
                      // Only date and time is aligned to the left, rest are center aligned in figma
                      return <Typography fontSize={TABLE_HEADER_FONT_SIZE} align={header === "Date & Time" ? "left" : "center"} key={key}>{headerLine}</Typography>
                    })}
                  </FixedHeightTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowDataSection}
            {addRecordSection}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

function getDataRowForRecord(key, patientData) {
  if (key === "created_date") {
    return getNiceDate(patientData[key]);
  } else if (key === "isMultipleUpload") {
    return parseInt(patientData[key]) === 0 ? UPLOAD_TYPE_STATUS.individual : UPLOAD_TYPE_STATUS.multiple;
  }
  return patientData[key];
}

// Rows for the left-hand side table
const PatientDataRow = ({ rowData, onRecordSelection }) => {
  const { pathname } = useLocation();
  const downloadRequestRecords = useSelector((state) => state.onDownloadRequest)
  const { patientData, patientDataKeys } = initPatientData(rowData);

  function doesRecordIsAlreadySelected() {
    if (isArrayEmpty(downloadRequestRecords)) {
      return false
    } else if (!isArrayEmpty(downloadRequestRecords.filter((record) => parseInt(record.deidentifie_id) === parseInt(rowData.deidentifie_id)))) {
      return true
    }
    return false
  }

  return (
    <TableRow sx={{ borderBottom: 1, borderBottomColor: AppColors.grey.secondary }}>
      {
        headerOptionsRoutes.includes(pathname) &&
        <FixedHeightTableCell>
          <Checkbox value={JSON.stringify(rowData)} checked={doesRecordIsAlreadySelected()} onChange={onRecordSelection} />
        </FixedHeightTableCell>
      }
      {patientDataKeys.map((rowKey, index) => {
        return (
          <FixedHeightTableCell
            align={index === 0 ? "left" : "center"}
            key={patientData[rowKey] + index.toString()}
            sx={{ color: "black", fontWeight: 600, fontSize: "14px" }}
          >
            {getDataRowForRecord(rowKey, patientData)}
          </FixedHeightTableCell>
        );
      })}
    </TableRow>
  );
};

const AddPatientDataRow = ({ formData, onChange, invalid = false }) => {
  const [localFormData, setLocalFormData] = useState(formData);
  const { pathname } = useLocation();

  const handleInputChange = (dataHeader, value) => {
    let updatedFormData = { ...localFormData };
    updatedFormData.data[dataHeader] = parseInt(value);
    setLocalFormData(updatedFormData);
    onChange(updatedFormData);
  };

  // In individual patient data, we do not want users to be inputting this data
  const textFieldData = () => {
    const fieldsToExclude = new Set(["created_date", "isMultipleUpload", "status", "riskScore",]);
    return Object.keys(localFormData.data).filter((field) => {
      return !fieldsToExclude.has(field);
    });
  };

  const MAX_TABLE_INPUT_DIGITS = 3;
  const TextInputs = textFieldData().map((dataHeader, index) => {
    return (
      <FixedHeightTableCell key={index} align="center">
        <TableTextField
          value={localFormData[dataHeader] === -1 ? "" : localFormData[dataHeader]}
          type={"number"}
          textFieldVariant={"nonDecimal"} // decimal or nonDecimal value has to be passed based upon that number validation is handled
          // Only deidentifie_id allows unlimited # of digits input- rest are limited
          maxDigits={dataHeader === 'deidentifie_id' ? null : MAX_TABLE_INPUT_DIGITS}
          onChange={(val) => handleInputChange(dataHeader, val)}
        />
      </FixedHeightTableCell>
    );
  });

  return (
    <TableRow sx={{ border: invalid ? 2 : 0, borderColor: invalid ? AppColors.error.main : AppColors.grey.error, }}>
      {headerOptionsRoutes.includes(pathname) && <FixedHeightTableCell><Checkbox /></FixedHeightTableCell>}
      <FixedHeightTableCell align="center">
        <TableDateTimePicker selectedDateTime={localFormData.data.created_date} onChange={(value) => handleInputChange("dateTime", value)} disabled />
      </FixedHeightTableCell>
      <FixedHeightTableCell sx={{ color: "black", fontWeight: 600, fontSize: "14px" }}>{UPLOAD_TYPE_STATUS.individual}</FixedHeightTableCell>
      {TextInputs}
    </TableRow>
  );
};

const RightSideTable = ({ patientData, addPatientDataRows, onRecordDeleted }) => {
  const displayStatusRows = patientData.map((rowData, index) => {
    const { patientData } = initPatientData(rowData);
    return <StatusTableRow patientData={patientData} key={index} onRecordDeleted={onRecordDeleted} />;
  });

  const displayAddPatientDataRows = addPatientDataRows.map((data, index) => {
    return <AddPatientDataStatusRow key={index} onRecordDeleted={onRecordDeleted} />;
  });

  return (
    <Box sx={{ height: "100%", borderLeft: 1, borderLeftColor: "white", boxSizing: "border-box", maxWidth: "100%", }}>
      <TableContainer sx={{ boxSizing: "border-box", maxWidth: "100%", borderStartEndRadius: '20px' }}>
        <Table size="small" sx={{ boxSizing: "border-box", maxWidth: "100%" }}>
          <TableHead sx={{ backgroundColor: AppColors.grey.dark, color: "white", height: '80px', fontSize: TABLE_HEADER_FONT_SIZE }}>
            <TableRow>
              <FixedHeightTableCell align="center">
                <Typography fontSize={TABLE_HEADER_FONT_SIZE} align={"center"}>Status</Typography>
              </FixedHeightTableCell>
              <FixedHeightTableCell align="center" >
                <Typography fontSize={TABLE_HEADER_FONT_SIZE} align={"center"}>Risk Score</Typography>
              </FixedHeightTableCell>
              <FixedHeightTableCell align="center" >
                <Typography fontSize={TABLE_HEADER_FONT_SIZE} align={"center"}>Action</Typography>
              </FixedHeightTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayStatusRows}
            {displayAddPatientDataRows}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

// Rows for the right-hand side table
const StatusTableRow = ({ patientData, onRecordDeleted }) => {
  return (
    <TableRow sx={{ borderBottom: 1, borderBottomColor: AppColors.grey.secondary }}>
      <FixedHeightTableCell align="center" sx={{ fontWeight: 600, fontSize: "14px" }}>
        <UploadStatusBarSlider riskScore={parseInt(patientData.riskScore)} />
      </FixedHeightTableCell>
      <FixedHeightTableCell align="center" sx={{ fontSize: "14px" }}>
        {!isUndefined(patientData.status_message) && !isEmpty(patientData.status_message) && <DashedBox text={patientData.status_message + "\n" + patientData.riskScore} color={getRiskColorByLabel(patientData.status_message)} padding={0.2} />}
      </FixedHeightTableCell>
      <FixedHeightTableCell align="center" sx={{ fontWeight: 600, fontSize: "14px" }}>
        <ViewDeleteActions patientData={patientData} onRecordDeleted={onRecordDeleted} />
      </FixedHeightTableCell>
    </TableRow>
  );
};

const AddPatientDataStatusRow = ({ onRecordDeleted }) => {
  // WHEN ADDING PATIENT DATA, THIS ROW WILL DISPLAY IN THE RIGHT TABLE
  return (
    <TableRow sx={{ boxSizing: "border-box" }}>
      <FixedHeightTableCell align="center" sx={{ fontWeight: 600, fontSize: "14px" }}>
        <UploadStatusBarSlider />
      </FixedHeightTableCell>
      <FixedHeightTableCell align="center" sx={{ fontWeight: 600, fontSize: "14px" }}>
        <span style={{ color: AppColors.appColor.secondary }}>N/A</span>{" "}
      </FixedHeightTableCell>
      <FixedHeightTableCell align="center" sx={{ fontWeight: 600, fontSize: "14px" }}>
        <ViewDeleteActions disableView onRecordDeleted={onRecordDeleted} />
      </FixedHeightTableCell>
    </TableRow>
  );
};

const ViewDeleteActions = (props) => {
  const { disableView = false, disableDelete = false, patientData = {}, onRecordDeleted } = props;
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [showLoader, setShowLoader] = useState(false)

  // Only be able to open backdrop when view icon is not disabled
  const handleOpenBackdrop = () => {
    if (!disableView) {
      setOpenBackdrop(true);
    }
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  async function handleDelete(selectedRecord) {
    setShowLoader(true)
    let formData = new FormData();
    formData.append('did_list', selectedRecord.deidentifie_id)
    formData.append('isDeleted', true)
    await deleteRecords(formData)
      .then((res) => {
        setShowLoader(false)
        onRecordDeleted(selectedRecord)
      })
      .catch((err) => setShowLoader(false))
  }
  return (
    <>
      <Loader show={showLoader} />
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
        <IconButton disabled={disableView} size="small" sx={{ color: AppColors.grey.dark }} onClick={handleOpenBackdrop}>
          <VisibilityIcon />
        </IconButton>
        <IconButton disabled={disableDelete} size="small" sx={{ color: AppColors.error.main }} onClick={() => handleDelete(patientData)}>
          <DeleteForeverIcon />
        </IconButton>
      </Stack>
      <Backdrop open={openBackdrop} style={{ zIndex: 1 }}>
        <ViewDataBackdrop closeAction={handleCloseBackdrop} patientData={patientData} />
      </Backdrop>
    </>
  );
};

const initPatientData = (rowData) => {
  let patientData = undefined;
  let patientDataKeys = undefined;

  if (rowData.data) {
    const fields = new Set(desired_record_fields);
    patientData = rowData.data;
    patientDataKeys = Object.keys(patientData).filter((key) => fields.has(key));
  } else {
    patientData = rowData;
    patientDataKeys = desired_record_fields;
  }

  return { patientData, patientDataKeys };
};

export default connect(store => store)(PatientsInformationTable);
