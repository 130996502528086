import { FormControl, FormLabel, Grid, InputLabel, MenuItem, Select, Stack, TablePagination, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { calculateTotalPages } from "../utils/utils";

const Pagination = ({ dataCount , pageChangeCallBack}) => {
    let pages = Array.from({ length: calculateTotalPages(dataCount) }, (_, index) => index + 1);
    const [selectedPage, setSelectedPage] = useState(1);

    const onPageSelection = (e) => {
        console.log(parseInt(e.target.value))
        setSelectedPage(parseInt(e.target.value))
        pageChangeCallBack(parseInt(e.target.value))
    }
    const onPageChangeUsingArrows = (event, newPage) => {
        setSelectedPage(newPage + 1)
        pageChangeCallBack(newPage + 1)
    }

    return (
        <Grid container justifyContent="flex-end">
            <Stack item direction={"row"}>
                <FormControl variant="standard">
                    <Stack direction={"row"}>
                        <Typography component={"p"} align="center" padding={2} fontSize={14}>Page</Typography>
                        <Select value={selectedPage} label="Page" onChange={onPageSelection} disableUnderline>
                            {pages.map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
                        </Select>
                    </Stack>
                </FormControl>

                <TablePagination
                    component="div"
                    count={dataCount}
                    page={(!dataCount || dataCount <= 0) ? 0 : selectedPage-1}
                    onPageChange={onPageChangeUsingArrows}
                    rowsPerPage={10}
                    rowsPerPageOptions={[]}
                />
            </Stack>
        </Grid>
    )
}

export default Pagination