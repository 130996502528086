import axios from 'axios';
import { setAxiosResponse } from '../store/reduxActions';

export function initiateInterceptor(dispatch) {

  const request = () => {
    return axios.interceptors.request.use((request) => {
      return request
    },
      (errorResponse) => {
        alert(errorResponse.message)
      }
    )
  }

  const response = () => {
    return axios.interceptors.response.use(
      (request) => { return request },
      (errorResponse) => {
        var errorBody = {
          statusCode: errorResponse.response.status,
          title : errorResponse.response.data.title,
          message: errorResponse.response.data.message,
          showModal: true
        }
        dispatch(setAxiosResponse(errorBody))
        // alert(errorResponse.response.data.message)
      }
    );
  }

  return { request, response }

}