import React from "react";
import { doesStringExists, isUndefined } from "../utils/utils";
import { Button, Typography } from "@mui/material";

const CustomUploadButton = ({name, color, icon , type , isFullWidth , acceptTypes , handleFileUpload}) => {

    const onFileUpload = (e) => {
        handleFileUpload(e)
    }

    return (
        doesStringExists(name) && doesStringExists(color) &&
        <Button component="label" variant="contained" type={type}  color={color} startIcon={!isUndefined(icon) && icon} sx={{height:'50px',width:!isUndefined(isFullWidth) && isFullWidth ? '100%' : '250px'}}>
            <Typography variant="p" color="#ffffff" fontWeight={600} >{name}</Typography>
            <input type="file" hidden accept={acceptTypes} onChange={onFileUpload} />
        </Button>
    )
}

export default CustomUploadButton