export const AppColors = {
  appColor: {
    main: "#004980",
    secondary: "#3797EF",
  },
  success: {
    main: "#388e3c",
  },
  error: {
    main: "#d32f2f",
  },
  warning: {
    main: "#ffc107",
  },
  grey: {
    main: "#ECF0F1",
    primary: "#f8f8f8",
    secondary: "#808080",
    light: "#bfc3c833",
    medium: "#201f1fb2",
    dark: "#3F4B5B", 
    shade: "#00000019",
  },
};
