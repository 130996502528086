import React, { useEffect, useState, useContext } from "react";
import PatientsTableInformation from "../../../components/PatientsInformationTable/PatientsInformationTable";
import { getRecords } from "../../../utils/services";
import Pagination from "../../../components/pagination";
import { isNull } from "../../../utils/utils";
import { HistoryFilterContext } from "../historyFilterContext";
import Loader from "../../../components/Loader/loader";
const UserHistory = (props) => {
  const [records, setRecords] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [showLoader, setLoader] = useState(false);
  const [page] = useState(1);
  const { filters } = useContext(HistoryFilterContext)

  useEffect(() => {
    getPatientRecords(page)
  }, [page, filters]);

  async function getPatientRecords(pageNo) {
    setLoader(true)
    let param = { 'page_no': pageNo, ...filters }
    await getRecords(param).then((res) => {
      if (!isNull(res.body)) {
        setRecords(res.body.data)
        setDataCount(res.body.dataCount)
      }
      setLoader(false)
    }).catch(()=>setLoader(false))
  }

  const onPageChange = async (pageNo) => {
    await getPatientRecords(pageNo)
  }

  // async function 
  return (
    <>
      <Loader show={showLoader}/>
      <PatientsTableInformation patientData={records} />
      <Pagination dataCount={dataCount} pageChangeCallBack={onPageChange} />
    </>
  )
};

export default UserHistory;
