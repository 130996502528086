import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import CustomButton from '../../../../components/button';
import CustomTextBox from '../../../../components/textbox';
import './organizationCreation.css';
import PhoneNumber from '../../../../components/PhoneNumberInput/phoneNumber';
import CustomAutocomplete from '../../../../components/AutoComplete/autoComplete';
import { ACTION_STATUS, ORGANIZATION_DETAILS } from '../../../../utils/constants';
import { validateAddress, validateContactNumber, validateDescription, validateEmail, validateOrganizationName, validatePostalCode, validateSelectCity, validateSelectCountry, validateSelectState, validateWebsiteURL } from '../../../../utils/validation';
import { useLocation, useNavigate } from 'react-router-dom';
import { isNull, isObjectEmpty, isObjecthasEmptyValues, isUndefined } from '../../../../utils/utils';
import Loader from '../../../../components/Loader/loader';
import ResponseModal from '../../../../components/responseModal';
import { addOrganization, getCityList, getCountyList, getStateList } from '../../../../utils/services';


const OrganizationCreation = () => {

  const initialOrganizationData = {
    organizationName: "",
    address: "",
    selectCountry: null,
    selectState: null,
    selectCity: null,
    postalCode: "",
    orgEmail: "",
    contactNumber: "",
    websiteUrl: "",
    description:""
  };

  const navigate = useNavigate();
  const { state } = useLocation();

  const [organizationData, setOrganizationData] = useState(initialOrganizationData);
  const [organizationFormError, setOrganizationFormError] = useState({});
  const [countryList, setCountryList] = useState([]);
	const [stateList, setStateList] = useState([]);
	const [cityList, setCityList] = useState([]);
  const [showLoader, setLoader] = useState(false);
  const [successResponse, setSuccessResponse] = useState({});
  const [showModal, setShowModal] = useState(false);

  const [displayUserStatus, setDisplayUserStatus] = useState();
  const isOrgDataNotNull = !isNull(state) && !isNull(state.orgData);
  const isViewOrganization  = isOrgDataNotNull && state.displayStatus === ACTION_STATUS.VIEW; //view status value 1

  const handleChange = (param, val) => {
    const error = validatedForm(param, val);
    let updatedData = {
      ...organizationData,
      [param]: val,
    };

    setOrganizationFormError({ ...organizationFormError, [param]: error })
    if (param === ORGANIZATION_DETAILS.SELECT_COUNTRY) {
      updatedData.selectState = null;
      updatedData.selectCity = null;
      getStatesList(val);
    } else if (param === ORGANIZATION_DETAILS.SELECT_STATE) {
      updatedData.selectCity = null;
      getCitiesList(val);
    }
    setOrganizationData(updatedData);
  }

  const validatedForm = (param, value) => {
    const validationFunctions = {
      [ORGANIZATION_DETAILS.ORGANIZATION_NAME]: validateOrganizationName,
      [ORGANIZATION_DETAILS.ADDRESS]: validateAddress,
      [ORGANIZATION_DETAILS.ORG_EMAIL]: validateEmail,
      [ORGANIZATION_DETAILS.CONTACT_NUMBER]: validateContactNumber,
      [ORGANIZATION_DETAILS.POSTAL_CODE]: validatePostalCode,
      [ORGANIZATION_DETAILS.WEBSITE_URL]: validateWebsiteURL,
      [ORGANIZATION_DETAILS.DESCRIPTION]: validateDescription,
    }
    
    const validationFunction = validationFunctions[param];
    if (validationFunction) return validationFunction(value);
  }

  const navigateToOrgList = () => { navigate("/dashboard/organization") }

  const handleSuccessModal = () => {
    setShowModal(false);
    navigateToOrgList();
  };

  const handleDisplayStatus = () => {setDisplayUserStatus(ACTION_STATUS.EDIT);};

  const isFormValidated = (data) => {
    let errors = {};
    errors.organizationName = validateOrganizationName(data.organizationName);
    errors.address = validateAddress(data.address);
    errors.orgEmail = validateEmail(data.orgEmail);
    errors.contactNumber = validateContactNumber(data.contactNumber);
    errors.postalCode = validatePostalCode(data.postalCode);
    errors.websiteUrl = validateWebsiteURL(data.websiteUrl);
    errors.description = validateDescription(data.description);
    errors.selectCity = validateSelectCity(data.selectCity);
    errors.selectState = validateSelectState(data.selectState);
    errors.selectCountry = validateSelectCountry(data.selectCountry);
    setOrganizationFormError(errors);
    return !isObjectEmpty(errors) && isObjecthasEmptyValues(errors);
  };

  async function getCountriesList() {
    setLoader(true);
  await getCountyList().then((res) => {
    if (!isNull(res.body)) {
      setCountryList(res.body.data);
    }
    setLoader(false);
  })
}

     //function to get the states list based on the country
  async function getStatesList(country) {
    setLoader(true);
      const params = { country_id: country.country_id };
    await getStateList(params).then((res) => {
      if (!isNull(res.body)) {
        setStateList(res.body.data);
      }
      setLoader(false);
    })
  }

  //function to get the city list based on the state
  async function getCitiesList(state) {
    setLoader(true);
    const params = { state_id: state.state_id };
      await getCityList(params).then((res) => {
        if (!isNull(res.body)) {
          setCityList(res.body.data);
        }
        setLoader(false);
      })
  }

  const handleSubmit = () => {
    if (isFormValidated(organizationData)) {
      const formData = new FormData();
      formData.append("org_name", organizationData.organizationName);
      formData.append("org_description", organizationData.description);
      formData.append("org_address", organizationData.address);
      formData.append("org_contact", "+1" + organizationData.contactNumber.replace(/-/g, ''));
      formData.append("org_website", organizationData.websiteUrl);
      formData.append("org_email",organizationData.orgEmail);
      formData.append("country_id",organizationData.selectCountry.country_id);
      formData.append("state_id",organizationData.selectState.state_id);
      formData.append("city_id",organizationData.selectCity.city_id);
      formData.append("org_zipcode", organizationData.postalCode);
      
      if (isOrgDataNotNull) {
        updateOrgForm(formData);
      } else {
        submitOrgForm(formData);
      }
    }
  };

  const submitOrgForm = async (form) => {
    setLoader(true);
    await addOrganization(form).then((res) => {
      if (!isNull(res.body)) {
        setShowModal(true);
        const response = {
          statusCode: res.statusCode,
          message: res.statusMessage
        }
        setSuccessResponse(response);
      }
      setLoader(false);
    }).catch(() => { setLoader(false); })
  };

  const updateOrgForm = async (form) => {
    // setLoader(true);
    // await updateOrganization(form).then((res) => {
    //   if (!isNull(res.body)) {
    //     setShowModal(true);
    //     const response = {
    //       statusCode: res.statusCode,
    //       message: res.statusMessage
    //     }
    //     setSuccessResponse(response);
    //   }
    //   setLoader(false);
    // }).catch(() => { setLoader(false); })
  };

  useEffect(() => {
    if (isOrgDataNotNull) {
      const newDisplayStatus = !isUndefined(displayUserStatus) ? displayUserStatus : state.displayStatus;
      state.displayStatus = newDisplayStatus;

      const initialOrganizationData = {
        organizationName: state.orgData.org_name,
        address: state.orgData.org_address,
        selectCountry: state.orgData || null,
        selectState: state.orgData || null,
        selectCity: state.orgData || null,
        postalCode: state.orgData.org_zipcode || "",
        orgEmail: state.orgData.org_email,
        contactNumber: state.orgData.org_contact.replace('+1', ''),
        websiteUrl: state.orgData.org_website,
        description: state.orgData.org_description
      };
      getCitiesList(state.orgData);
      getStatesList(state.orgData);
      setOrganizationData(initialOrganizationData);
      setDisplayUserStatus(newDisplayStatus); // Update the edit status of the user actions;
    }
    getCountriesList();
  }, [displayUserStatus]);


  return (
    <Box component="div" className='organization-creation-container'>
      <Loader show={showLoader} />
      {showModal === true && <ResponseModal showModal={showModal} message={successResponse.message} statusCode={successResponse.statusCode} onClick={handleSuccessModal}/>}

    {isViewOrganization &&
      <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={2}        
    >
      <CustomButton type="button" color="secondary" name="Go Back"  onClick={navigateToOrgList}/>
      {/* <CustomButton type="button" color="primary" name="Edit" onClick={()=>console.log("edit")} /> */}
    </Stack>
    }
      <Box component="div" className='organization-creation-content' sx={{mt: isViewOrganization ? 2 : 4}}>
    <Grid container spacing={2}>
      <Grid item  md={12}>
        <CustomTextBox
          value={organizationData.organizationName}
          onChange={(val) => handleChange(ORGANIZATION_DETAILS.ORGANIZATION_NAME, val)}
          customLabelName="org-label-name"
          customClassName={"org-textbox"}
          placeholder={"Enter organization name"}
          title={"Organization Name"}
          type={"text"}
          error={Boolean(organizationFormError.organizationName)}
          helperText={organizationFormError.organizationName}
          disabled={isViewOrganization}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextBox
          value={organizationData.address}
          onChange={(val) => handleChange(ORGANIZATION_DETAILS.ADDRESS, val)}
          customLabelName="org-label-name"
          customClassName={"org-textbox"}
          placeholder={"Enter  your address"}
          title={"Address"}
          type={"text"}
          error={Boolean(organizationFormError.address)}
          helperText={organizationFormError.address}
          disabled={isViewOrganization}
        />
      </Grid>
      <Grid item md={6}>
        <CustomTextBox
          value={organizationData.orgEmail}
          onChange={(val) => handleChange(ORGANIZATION_DETAILS.ORG_EMAIL, val)}
          customLabelName="org-label-name"
          customClassName={"org-textbox"}
          placeholder={"Enter your email address"}
          title={"Email ID"}
          type={"text"}
          error={Boolean(organizationFormError.orgEmail)}
          helperText={organizationFormError.orgEmail}
          disabled={isViewOrganization}
        />
          </Grid>
          <Grid item md={6}>
          <CustomAutocomplete
            disableClearable={true}
            options={countryList}
            getOptionLabel={(option) => option.country_name}
            // value={organizationData.selectCountry}
            value={organizationData.selectCountry ? countryList.find(country => country.country_id === organizationData.selectCountry.country_id) : null}
            onChange={(event,val) => handleChange(ORGANIZATION_DETAILS.SELECT_COUNTRY, val)}
            customLabelName="org-label-name"
            fullWidth={true}
            label="Country"
            placeholder={"select a Country"}
            error={Boolean(organizationFormError.selectCountry)}
            helperText={organizationFormError.selectCountry}
            disabled={isViewOrganization}
          />
          </Grid>
          <Grid item md={6}>
          <CustomAutocomplete
            disableClearable={true}
            options={stateList}
            getOptionLabel={(option) => option.state_name}
            value={organizationData.selectState ? stateList.find(state => state.state_id === organizationData.selectState.state_id) : null}
            // value={organizationData.selectState}
            onChange={(event,val) => handleChange(ORGANIZATION_DETAILS.SELECT_STATE, val)}
            customLabelName="org-label-name"
            fullWidth={true}
            label="State"
            placeholder={"select a State"}
            error={Boolean(organizationFormError.selectState)}
            helperText={organizationFormError.selectState}
            disabled={isViewOrganization}
          />
          </Grid>
          <Grid item md={6}>
          <CustomAutocomplete
            disableClearable={true}
            options={cityList}
            getOptionLabel={(option) => option.city_name}
            // value={organizationData.selectCity}
            value={organizationData.selectCity ? cityList.find(city => city.city_id === organizationData.selectCity.city_id) : null}
            onChange={(event,val) => handleChange(ORGANIZATION_DETAILS.SELECT_CITY, val)}
            customLabelName="org-label-name"
            fullWidth={true}
            label="City"
            placeholder={"select a City"}
            error={Boolean(organizationFormError.selectCity)}
            helperText={organizationFormError.selectCity}
            disabled={isViewOrganization}
          />
          </Grid>
           <Grid item md={6} >
        <PhoneNumber
          value={organizationData.postalCode}
          onChange={(val) => handleChange(ORGANIZATION_DETAILS.POSTAL_CODE, val)}
          customLabelName="org-label-name"
          placeholder={"Enter your  postal code"}
          label={"Postal Code"}
          format={"#####"}
          maxLength={5}
          fullWidth={true}
          error={Boolean(organizationFormError.postalCode)}
          helperText={organizationFormError.postalCode}
          disabled={isViewOrganization}
        />
      </Grid>
      <Grid item md={6}>
        <PhoneNumber
          value={organizationData.contactNumber}
          onChange={(val) => handleChange(ORGANIZATION_DETAILS.CONTACT_NUMBER, val)}
          customLabelName="org-label-name"
          placeholder={"Enter your phone number"}
          label={"Contact"}
          format={"###-###-####"}
          maxLength={13}
          fullWidth={true}
          error={Boolean(organizationFormError.contactNumber)}
          helperText={organizationFormError.contactNumber}
          disabled={isViewOrganization}
        />
          </Grid>
          <Grid item md={6}>
        <CustomTextBox
          value={organizationData.websiteUrl}
          onChange={(val) => handleChange(ORGANIZATION_DETAILS.WEBSITE_URL, val)}
          customLabelName="org-label-name"
          customClassName={"org-textbox"}
          placeholder={"Enter your website url"}
          title={"Website URL"}
          type={"text"}
          error={Boolean(organizationFormError.websiteUrl)}
          helperText={organizationFormError.websiteUrl}
          disabled={isViewOrganization}
        />
          </Grid>
          <Grid item md={12}>
          <CustomTextBox
          value={organizationData.description}
          onChange={(val) => handleChange(ORGANIZATION_DETAILS.DESCRIPTION, val)}
          customLabelName="org-label-name"
          customClassName={"org-textbox"}
          placeholder={"Enter your description"}
          title={"Description"}
          multiline={true}
          rows={6}              
          error={Boolean(organizationFormError.description)}
          helperText={organizationFormError.description}
          disabled={isViewOrganization}
        />
          </Grid>
    </Grid>
    </Box>
    {!isViewOrganization &&
      <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={8}
    >
      <CustomButton type="button" variant="outlined" labelColor="primary" name="Go Back" onClick={navigateToOrgList} />
      <CustomButton type="submit" color="primary" name={isOrgDataNotNull ? "Update" : "Submit"}  onClick={handleSubmit}/>
    </Stack>
    }
  </Box>
  )
}

export default OrganizationCreation
