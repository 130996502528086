import React from 'react';
import { InputAdornment } from '@mui/material';
import SearchIcon from "../../assets/images/search-icon.svg";
import { StyledTextField } from '../../utils/constants';
import './searchBar.css'


const SearchBar = (props) => {
  const { variant, placeholder, fullWidth, onChange, value } = props;

  return (
    <StyledTextField
      variant={variant}
      placeholder={placeholder}
      className="searchField"
      fullWidth={fullWidth}
      onChange={onChange}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position={"start"}>
            <img src={SearchIcon} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;
