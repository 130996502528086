import React, { useState } from "react";
import { Box, Stack, Modal, Button, Typography } from "@mui/material";
import { AppColors } from "../../../../utils/colors";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import LoadingGif from "./../../../../assets/images/multi_file_upload_loading.gif";
import SuccessUploadGif from "./../../../../assets/images/success_upload.gif";
import { isArrayEmpty, isNull, isUndefined } from "../../../../utils/utils";
import { addBulkRecords } from "../../../../utils/services";
import PatientsInformationTable from "../../../../components/PatientsInformationTable/PatientsInformationTable";
import CustomButton from "../../../../components/button";
import CustomUploadButton from "../../../../components/uploadButton";
import DecisionModal from "../../../../components/decisionModal";

const UploadMultiplePatientsSection = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState()
  const [uploadedRecords, setUploadedRecords] = useState([])

  const toggleUploadModal = () => setModalOpen(!modalOpen)

  const handleDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    event.currentTarget.style.backgroundColor = AppColors.grey.main;

    let droppedFile = event.dataTransfer.files[0];
    if (!isUndefined(droppedFile)) {
      setSelectedFile(droppedFile)
      setModalOpen(true)
      fileUploadService(droppedFile)
    } return;
  }

  const handleFileUpload = (e) => {
    if (!isUndefined(e.target.files[0])) {
      let file = e.target.files[0];
      setSelectedFile(file)
      setModalOpen(true)
      fileUploadService(file)
    } return;
  }

  async function fileUploadService(file) {
    setUploading(true)
    let formData = new FormData();
    formData.append('record_file', file)
    await addBulkRecords(formData).then((res) => {
      setUploadedRecords(res.body.data)
      setUploading(false)
    })
  }


  return (
    <>
      <Box onDragOver={handleDrop} onDrop={handleDrop} sx={{ backgroundColor: "white", border: `1px dashed ${AppColors.grey.main}`, borderRadius: "10px"}} >
        {
          isArrayEmpty(uploadedRecords) ?
            <Stack direction="column" justifyContent="center" alignItems="center" sx={{ backgroundColor: AppColors.grey.light, borderRadius: "inherit", border: `1px dashed ${AppColors.grey.main}`, padding: 5, }}>
              <UploadFileIcon style={{ fontSize: "50" }} />
              <Typography color={AppColors.grey.medium} fontWeight={600} fontSize={18}> Drag and Drop Files Here </Typography>
              <Typography color={AppColors.grey.medium} fontSize={18}> OR </Typography>
              <Button component="label" color={'secondary'} variant="text" sx={{ fontWeight: 600 }} role={undefined} tabIndex={-1}>
                Browse
                <input type="file" hidden accept=".csv" onChange={handleFileUpload} />
              </Button>
              <Typography color={AppColors.appColor.main} fontSize={14} fontStyle={'italic'}> Supported Format: .CSV </Typography>
            </Stack> :
            <RecordsView data={uploadedRecords} onFileUpload={handleFileUpload} />
        }
      </Box>
      <DecisionModal
        showModal={modalOpen}
        title={isUploading ? 'Uploading...' : 'Successfully Uploaded'}
        message={isUploading ? !isUndefined(selectedFile) && selectedFile.name : 'Total No. of Records: ' + uploadedRecords.length}
        icon={isUploading ? LoadingGif : SuccessUploadGif}
        handleModalState={toggleUploadModal}
      />
    </>
  );
};

const RecordsView = ({ data, onFileUpload }) => {
  return (
    <Stack>
      {!isUndefined(data) && !isNull(data) && <PatientsInformationTable patientData={data} />}
      <CustomUploadButton name="Upload" color="primary" acceptTypes={'.csv'} handleFileUpload={onFileUpload} />
    </Stack>
  )
}

const UploadingModalScene = ({ file }) => {
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={4} sx={{ boxSizing: "border-box", height: "100%", backgroundColor: "white", }}>
      <img src={LoadingGif} alt="In Progress..." style={{ width: "30%", height: "auto" }} />
      <h2>Uploading...</h2>
      <Typography color={AppColors.grey.medium} fontSize={18}>{!isUndefined(file) && file.name}</Typography>
    </Stack>
  );
};

const UploadingModalScene2 = ({ cancel, submit }) => {
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={4} sx={{ boxSizing: "border-box", height: "100%", backgroundColor: "white", }}>
      <img src={SuccessUploadGif} alt="In Progress..." style={{ width: "10%", height: "auto" }} />
      <Typography variant="h4">Successfully Uploaded</Typography>
      <span> Total No. of Records: <span style={{ backgroundColor: AppColors.appColor.secondary, padding: "5px", color: "white", borderRadius: "100px", }}>77</span> </span>
    </Stack>
  );
};

const SuccessfullyUploadedModalScene = ({ cancel, submit }) => {
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={4} sx={{ boxSizing: "border-box", height: "100%", backgroundColor: "white", }}>
      <img src={SuccessUploadGif} alt="Success" style={{ height: "auto" }} />
      <span style={{ color: AppColors.success.main }}> Successfully Uploaded </span>
      <span> Total No. of Records: <span style={{ backgroundColor: AppColors.appColor.secondary, padding: "5px", color: "white", borderRadius: "100px", }}>77</span> </span>
      <span style={{ fontSize: "0.8rem" }}> Submit your records for seamless processing </span>
      <Stack direction="row" spacing={2}>
        <Button variant="contained" color="error" onClick={cancel}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={submit}>Submit</Button>
      </Stack>
    </Stack>
  );
};

export default UploadMultiplePatientsSection;
