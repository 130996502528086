import { Box, Typography } from "@mui/material";
import React from "react";
import { capitalizeFirstLetter, isUndefined } from "../utils/utils";

const DashedBox = ({ text, color , padding}) => {
  return (
    <Box sx={{ border: `2px dashed ${color}`, borderRadius: "5px", color: `${color}`, textAlign: 'center' }}>
      <Typography variant="p" fontWeight={600} padding={padding} color={color}>{capitalizeFirstLetter(text)}</Typography>
    </Box>
  )
}

export default DashedBox