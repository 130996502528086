import React from "react";
import "./PageContentHeader.css";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Breadcrumbs, Box, Link } from "@mui/material";
import { headerOptionsRoutes } from "../../utils/routes";
import DateFilterSaveOptions from "../DateFilterSaveOptions/DateFilterSaveOptions";
import { capitalizeFirstLetter } from "../../utils/utils";
import { AppColors } from "../../utils/colors";

/*
  Call this PageContentHeader at the start of any page
  pageActionsComponent is a prop that takes a component which will be rendered on the right hand
  side of the page. The breadcrumbs will be automatically set depending on the page it is rendered on 
  by looking at the route
*/

const PageContentHeader = (props) => {
  const { pathname } = useLocation();
  return (
    <Box className="page-content-header-container">
      <HeaderBreadCrumbs />
      {headerOptionsRoutes.includes(pathname) && (
        <Box>
          <DateFilterSaveOptions />
        </Box>
      )}
    </Box>
  );
};

/*
At the top left of every page header, the title of the page as well as the route to the 
page in the form of breadcrumbs element will be displayed

This component extracts the title and the links by looking at the route automatically
*/
const HeaderBreadCrumbs = () => {
  const { pathname } = useLocation();
  const pathSegments = pathname.split("/").filter((segment) => segment);
  const currPageName = capitalizeFirstLetter(
    pathSegments[pathSegments.length - 1]
  );

  return (
    <Box>
      <span style={{ fontSize: 30 }}>{currPageName}</span>
      <br />
      <Breadcrumbs>
        {pathSegments.map((segment, index) => {
          const path = `/${pathSegments.slice(0, index + 1).join("/")}`;
          const displayText = capitalizeFirstLetter(segment);
          return (
            <Link
              key={path}
              component={RouterLink}
              to={path}
              sx={{ color: AppColors.grey.secondary, textDecoration: "none" }}
            >
              {displayText}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default PageContentHeader;
