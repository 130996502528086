import axios from "axios";
import { isUndefined } from "./utils";
import { URL } from "./url";

function returnErrorStructure(response) {
  var responseStructure = { statusCode: null, body: null, statusMessage: null }
  if (!isUndefined(response)) {
    responseStructure.statusCode = response.status;
    responseStructure.body = null;
    responseStructure.statusMessage = !isUndefined(response.data.message) && response.data.message;
  }
  return responseStructure
}

function returnSuccessResponse(response) {
  var responseStructure = { statusCode: null, body: null, statusMessage: null }
  if (!isUndefined(response)) {
    responseStructure.statusCode = response.status;
    responseStructure.body = response.data;
    responseStructure.statusMessage = response.data.message;
  }
  return responseStructure
}

export const login = async (form, isRemember) => {
  return await axios.post(URL.LOGIN, form).then((res) => {
    return returnSuccessResponse(res)
  }).catch((err) => {
    return returnErrorStructure(err.response);
  })
};

export const getRecords = async (params) => {
  return await axios.get(URL.FETCH_RECORDS, { params: params }).then((res) => {
    return returnSuccessResponse(res)
  }).catch((err) => {
    return returnErrorStructure(err.response)
  });
};

export const addRecords = async (formData) => {
  return await axios.post(URL.ADD_INDIVIDUAL_RECORDS, formData).then((res) => {
    return returnSuccessResponse(res)
  }).catch((err)=>{
    return returnErrorStructure(err.response)
  })
};

export const addBulkRecords = async (formData) => {
  return await axios.post(URL.ADD_MULTIPLE_RECORDS, formData).then((res) => {
    return returnSuccessResponse(res)
  }).catch((err)=>{
    return returnErrorStructure(err.response)
  })
};

export const deleteRecords =  async (formData) =>{
  return await axios.post(URL.DELETE_RECORDS, formData).then((res) => {
    return returnSuccessResponse(res)
  }).catch((err)=>{
    return returnErrorStructure(err.response)
  })
}

export const getUserList = async (params) => {
  return await axios.get(URL.FETCH_USER_LIST, { params: params }).then((res) => {
    return returnSuccessResponse(res);
  }).catch((err) => {
    return returnErrorStructure(err.response);
  }); 
}

export const getOrgList = async (params) => {
  return await axios.get(URL.FETCH_ORG_LIST, { params: params }).then((res) => {
    return returnSuccessResponse(res);
  }).catch((err) => {
    return returnErrorStructure(err.response);
  }); 
}

export const getUserRoleList = async (params) => {
  return await axios.get(URL.FETCH_ROLE_LIST, { params: params }).then((res) => {
    return returnSuccessResponse(res);
  }).catch((err) => {
    return returnErrorStructure(err.response);
  }); 
}

export const addUser = async (formData) => {
  return await axios.post(URL.ADD_USER, formData).then((res) => {
    return returnSuccessResponse(res)
  }).catch((err)=>{
    return returnErrorStructure(err.response)
  })
};

export const updateUser = async (formData) => {
  return await axios.post(URL.UPDATE_USER, formData).then((res) => {
    return returnSuccessResponse(res)
  }).catch((err)=>{
    return returnErrorStructure(err.response)
  })
};

export const addOrganization = async (formData) => {
  return await axios.post(URL.ADD_ORGANIZATION, formData).then((res) => {
    return returnSuccessResponse(res)
  }).catch((err)=>{
    return returnErrorStructure(err.response)
  })
};

export const getCountyList = async () => {
  return await axios.get(URL.FETCH_COUNTRIES).then((res) => {
    return returnSuccessResponse(res);
  }).catch((err) => {
    return returnErrorStructure(err.response);
  }); 
}

export const getStateList = async (params) => {
  return await axios.get(URL.FETCH_STATES, { params: params }).then((res) => {
    return returnSuccessResponse(res);
  }).catch((err) => {
    return returnErrorStructure(err.response);
  }); 
}

export const getCityList = async (params) => {
  return await axios.get(URL.FETCH_CITIES, { params: params }).then((res) => {
    return returnSuccessResponse(res);
  }).catch((err) => {
    return returnErrorStructure(err.response);
  }); 
}

export const deleteOrganization = async (formData) => {
  return await axios.post(URL.DELETE_ORGANIZATION, formData).then((res) => {
    return returnSuccessResponse(res)
  }).catch((err)=>{
    return returnErrorStructure(err.response)
  })
};

export const deleteUser = async (formData) => {
  return await axios.post(URL.DELETE_USER, formData).then((res) => {
    return returnSuccessResponse(res)
  }).catch((err)=>{
    return returnErrorStructure(err.response)
  })
};

export const getRangeFieldMaxMinValues = async () => {
  return await axios.get(URL.FETCH_RANGE_FIELD_MAX_MIN_VALUES).then((res) => {
    return returnSuccessResponse(res);
  }).catch((err) => {
    return returnErrorStructure(err.response);
  }); 
}
