import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./sidebar.css";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { isUndefined } from "../../utils/utils";
import { sidebarMenu } from "./data";
import PageContentHeader from "../PageContentHeader/PageContentHeader";
import { HistoryFilterContext } from "../../modules/dashboard/historyFilterContext";
import { routesWithoutBackground } from "../../utils/routes";

const Sidebar = ({ children }) => {
  const { pathname } = useLocation();
  const [filters, setFilters] = useState({})

  function showActiveMenuItem() {
    let activeId;
    sidebarMenu.forEach((menu, menuIndex) => {
      const isPath = !isUndefined(menu.path) && menu.path === pathname;
      if (isPath) {
        activeId = menuIndex;
      }
    });
    return activeId;
  }

  return (
    <>
      <Grid container direction={"row"} className="sidebar-container">
        <Grid item md={1} xs={2} sm={1} xl ={1}className="sidebar-wrapper">
          <List>
            {sidebarMenu.map((menu, index) => {
              return (
                <NavLink to={menu.path} key={index} className={showActiveMenuItem() === index ? "link active" : "link"}>
                  <div className="icon" title={menu.name}>{menu.icon}</div>
                </NavLink>
              );
            })}
          </List>
        </Grid>
        <Grid item md={11} xl ={11} className="content-wrapper">
          <HistoryFilterContext.Provider value={{ filters, setFilters }}>
            <PageContentHeader />
            <div className={routesWithoutBackground.includes(pathname) ? "content-main" : "content-main whiteBackgroundColor"}>{children}</div>
          </HistoryFilterContext.Provider>
        </Grid>
      </Grid>
    </>
  );
};

export default Sidebar;
