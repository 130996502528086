import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { isArrayEmpty } from '../../utils/utils';
import { AppColors } from '../../utils/colors';
import Pagination from '../pagination';


const CustomTable = ({ headerColumns, render, tdata, pageChangeCallBack, dataCount }) => {
  return (
    <>
    <TableContainer component={Paper} sx={{ borderRadius: '10px 10px 0 0', mt: 5 }}>
      <Table size="small">
        <TableHead sx={{ backgroundColor: AppColors.appColor.main, height: '60px'}}>
          <TableRow>
            {!isArrayEmpty(headerColumns) && headerColumns.map((column) => (
              <TableCell
                key={column.id}
                component="th"
                sx={{ color: "#FFF", fontWeight: 600, fontSize: 15 }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isArrayEmpty(tdata) && tdata.map(render)}
        </TableBody>
      </Table>
      </TableContainer>
      {isArrayEmpty(tdata) && (<Typography variant='body1' align="center" sx={{ mt: 4 }}>No records found to display!</Typography>)}
      
      <Pagination dataCount={dataCount} pageChangeCallBack={pageChangeCallBack} />
      </>
  )
}

export default CustomTable;
