import React from "react";
import { Stack } from "@mui/material";
import { AppColors } from "../utils/colors";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';

// For N/A upload Percent, use -1
const UploadStatusBarSlider = ({ riskScore = -1}) => {
  let statusBarColor = AppColors.success.main;
  let labelText = '' , statusMessage = ''
  if(riskScore === -1){
    statusBarColor = AppColors.appColor.secondary;
    labelText = "N/A";
    statusMessage = '';
  }else if(riskScore === 0){
    statusBarColor = AppColors.warning.main;
    labelText = <PendingIcon color="warning" />;
    statusMessage = 'Processing'
  }else if(riskScore > 0){
    statusBarColor = AppColors.success.main;
    labelText = <CheckCircleIcon color="success"/>;
    statusMessage = 'Completed'
  }

  return (
    <Stack direction="column" alignItems="center" sx={{ color: statusBarColor, width: "100%" }}>
      <div title={statusMessage}>{labelText}</div>
    </Stack>
  );
};

export default UploadStatusBarSlider;
