import {styled, TableCell} from '@mui/material'

const FixedHeightTableCell = styled(TableCell)(({ theme }) => ({
  color: "inherit",
  fontSize: '14px',
  height: "50px",
  width: "fit-content",
  whiteSpace: "nowrap", // Add this property to prevent text wrapping
  padding : '8px',
}));

export default FixedHeightTableCell;
