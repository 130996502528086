import { Feed, CorporateFare, Home , PeopleAlt} from "@mui/icons-material";
export const sidebarMenu = [
    {
        path: "/dashboard/home",
        name: "Home",
        icon : <Home/>
    },
    {
        path: "/dashboard/history",
        name: "History",
        icon : <Feed/>
    },
    {
        path: "/dashboard/organization",
        name: "Organization",
        icon : <CorporateFare/>
    },
    {
        path: "/dashboard/users",
        name: "User",
        icon : <PeopleAlt/>
    },
]