import { AppColors } from "./colors";
import {
  ACCEPTING_MIME_TYPES,
  ACTION_STATUS,
  Bytes,
  ByteValue,
  GigaByte,
  imageSizeLimit,
  KiloByte,
  MegaByte,
  USER_ROLES,
} from "./constants";
import * as XLSX from 'xlsx';
import { getDataLocally, STORAGE_KEYS } from "./localStorage";

export function isUndefined(value) {
  return value === undefined;
}

export function isNull(value) {
  return value === null || value === "null";
}

export function isEmpty(value) {
  return value === "";
}

export function doesStringExists(str) {
  return !isUndefined(str) && !isNull(str) && !isEmpty(str);
}

export function isObjectEmpty(object) {
  return Object.keys(object).length === 0;
}
export function isObjecthasEmptyValues(object) {
  return Object.values(object).every((o) => o === "");
}
export function isObjecthasNullValues(object) {
  return Object.values(object).every((o) => o === null);
}

export function getObjectKeyValues(object) { 
  return Object.keys(object);
}
export function isArrayEmpty(array) {
  return array.length === 0;
}

export function isInteger(num) {
  return isNaN(num);
}

export function truncate(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

export function formatDate(value) {
  var date = new Date(value);
  date = date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
  return date;
}

export const getOrgEncryptedId = () => {
  var encodedId = window.location.href;
  encodedId = encodedId.split("/");
  return encodedId[encodedId.length - 1];
};

export const getNumbersFromString = (str) => {
  return str.replace(/\D/g, "");
};

export const getNiceFileSize = (size) => {
  var fSExts = [Bytes, KiloByte, MegaByte, GigaByte];
  var i = 0;
  while (size > 900) {
    size /= 1024;
    i++;
  }
  var exactSize = {
    size: Math.round(size * 100) / 100,
    unit: fSExts[i],
  };
  return exactSize;
};

export const getPaginationPageDetailsCalculation = (pageDetails) => {
  var currentFromRec = 0,
    currentToRec = 0;
  if (!isNull(pageDetails)) {
    if (pageDetails.pageNo > 1) {
      //executes if page number > 1
      if (pageDetails.pageNo === pageDetails.totalPages) {
        // execute if the page number is the last page
        currentFromRec = (pageDetails.pageNo - 1) * pageDetails.offset + 1;
        currentToRec = currentFromRec + pageDetails.recordsPerPage - 1;
      } else {
        // execute for all the pages before the last page
        currentFromRec =
          pageDetails.pageNo * pageDetails.offset - pageDetails.offset + 1;
        currentToRec =
          pageDetails.offset * pageDetails.pageNo +
          pageDetails.recordsPerPage -
          pageDetails.offset;
      }
    } else {
      //executes if page number <= 1
      currentFromRec = 1;
      currentToRec = pageDetails.recordsPerPage;
    }
  }
  return (
    currentFromRec + "-" + currentToRec + " of " + pageDetails.totalRecords
  );
};

export const onImageUploadValidation = (image) => {
  var validationResponse;
  if (
    ACCEPTING_MIME_TYPES.includes(
      !isUndefined(image.target.files[0]) &&
      !isNull(image.target.files[0]) &&
      image.target.files[0].type
    )
  ) {
    var exactSize = getNiceFileSize(image.target.files[0].size);
    if (
      (exactSize.unit === Bytes && exactSize.size <= ByteValue) ||
      (exactSize.unit === KiloByte && exactSize.size <= imageSizeLimit)
    ) {
      validationResponse = {
        isValidated: true,
        statusMessage: "success",
      };
    } else {
      validationResponse = {
        isValidated: false,
        statusMessage:
          "Image size is too large , Please upload a different image",
      };
    }
  } else {
    validationResponse = {
      isValidated: false,
      statusMessage: "Please choose an image with allowed formats",
    };
  }
  return validationResponse;
};

export function isPayloadMalicious(payloadValues) {
  var isExists = false;
  payloadValues.forEach((value) => {
    if (isInjection(value)) {
      isExists = true;
    }
  });
  return isExists;
}

export function isInjection(str) {
  if (containsHtml(str)) {
    // Check for HTML injection
    return true;
  } else if (containsJavascript(str)) {
    // Check for JavaScript injection
    return true;
  } else if (containsSqlQueries(str)) {
    // Check for SQL
    return true;
  } else if (containsFileInclusion(str)) {
    // Check for file inclusion
    return true;
  }
  return false;
}

export const containsHtml = (str) => {
  const htmlRegex =
    /<\s*(\b(?!(img|br|hr)\b)\w+)\b[^>]*>.*?<\s*\/\s*\1\s*>|<\s*(img|br|hr)\b[^>]*>/gi;
  return htmlRegex.test(str);
};

export const containsJavascript = (str) => {
  const javascriptRegex =
    /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>|javascript:|on\w+\s*=\s*"/gi;
  return javascriptRegex.test(str);
};

export const containsSqlQueries = (str) => {
  const pattern =
    /(?:')|(?:--)|\/\*|\b(ALTER|CREATE|DELETE|DROP|EXEC(UTE){0,1}|INSERT( +INTO){0,1}|MERGE|SELECT|UPDATE)\b/i;
  return pattern.test(str);
};

export const containsFileInclusion = (str) => {
  const pattern1 = /(\.|\/)(asp|php|jsp|exe|sh|pl)$/i;
  const pattern2 = /(include|require)(_once)?\(['"].+['"]\)/i;
  return pattern1.test(str) || pattern2.test(str);
};

export const cleanArray = (list) => {
  return list.filter(function (element) {
    return element !== undefined;
  });
};

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export function getStatusColorByStatus(status) {
  if (!isEmpty(status) && status.toLowerCase() === "completed") {
    return "success";
  } else if (!isEmpty(status) && status.toLowerCase() === "processing") {
    return "warning";
  } else {
    return "primary";
  }
}

export function isNameValidated(name) {
  const nameRegex = /^[A-Za-z\s\-',.]+$/;
  return nameRegex.test(name);
}

export function isNameValidatedIncludingSpaces(name) {
  const nameRegex = /^[a-zA-Z\s]+$/;
  return nameRegex.test(name);
}

export function isEmailValidated(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}


export function isWebsiteValidated(website) { 
  const websiteUrlRegex =/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return websiteUrlRegex.test(website);

}
// Returns JSON Object with
/*
    {
        riskLabel: 'string',
        riskColor: 'string
    }
*/
export function getRiskColorByLabel(label) {
  return label.toLowerCase() === 'high' ? AppColors.error.main : AppColors.appColor.main
}

export const capitalizeFirstLetter = (inputString) => {
  return inputString.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

export const getUploadType = (type) => {
  if (type === 0) {
    return "(Individual) PI"
  } else if (type === 1) {
    return "(Multiple) PI"
  }
  return "-"
}

export const getNiceDate = (date) => {
  let niceDate = new Date(date);
  return (niceDate.getMonth() + 1) + '-' + niceDate.getDate() + '-' + niceDate.getFullYear();
}

export const getNiceDateTime = (date) => {
  let niceDate = new Date(date);
  return (niceDate.getMonth() + 1) + '-' + niceDate.getDate() + '-' + niceDate.getFullYear() + " " + niceDate.getHours() + ":" + niceDate.getMinutes();
}

export function extractKeyValuePairs(object, keysArray) {
  const result = {};

  keysArray.forEach(key => {
    if (object.hasOwnProperty(key)) {
      result[key] = object[key];
    }
  });

  return result;
}

export function calculateTotalPages(dataCount) {
  return Math.ceil(dataCount / 10); // 10 is set as default page size
}

// Either A, or B, but NOT both
export function exclusiveOR(boolA, boolB) {
  return (boolA && !boolB) || (boolB && !boolA);

}

export function convertJsonToExcel(data){
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, "DataSheet.xlsx");
}

// Pass in typeof Date variable
export function addTime(date, hours = 0, minutes = 0, seconds = 0) {
  if (date instanceof Date) {
    let newDate = new Date(date); // Create a new date to avoid modifying the original one
    newDate.setTime(date.getTime() + (hours * 60 * 60 * 1000) + (minutes * 60 * 1000) + (seconds * 1000));
    return newDate;
  }
  return null
}

export const isSuperAdmin = () => {
  const getLoggedInOrganization = getDataLocally(STORAGE_KEYS.LOGIN_ORGANIZATION);
  return !isNull(getLoggedInOrganization) && getLoggedInOrganization.role_id === USER_ROLES.SUPER_ADMIN
}

export const displayStatusByActions = (editStatus, displayStatus) => {
  return editStatus === ACTION_STATUS.EDIT ? ACTION_STATUS.EDIT : displayStatus;
}