import React from "react";
import { TextField } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { isNull } from "../../../utils/utils";

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    fontSize: "14px",
    textAlign: "center",
    height: "30px",
    width: "75px",
    padding: "0px",
    fontWeight: 700
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const TableTextField = ({ value, onChange, type, textFieldVariant, maxDigits = null }) => {
  const theme = useTheme(); // Access the MUI theme

  const onTextChange = (e) => {
    if (!isNull(maxDigits) && e.target.value.length > maxDigits) {
      return; // Exit early if the new value would exceed max digits
    }
    onChange(e.target.value)
  }

  const onTextKeyDown = (e) => {
    // Allow backspace, arrow keys, delete, and other control keys
    const isControlKey = ["Backspace", "ArrowLeft", "ArrowRight", "Delete", "ArrowUp", "ArrowDown", "Tab", "Shift", "Control", "Alt"].includes(e.key);

    if (!isControlKey && !isNull(maxDigits) && `${e.target.value}`.length >= maxDigits && e.key.match(/\S/)) {
      e.preventDefault();
      return;  // Exit early from the function
    }

    if (textFieldVariant === 'nonDecimal') {
      if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "=" || e.key === ".") {
        e.preventDefault();
      }
    } else if (textFieldVariant === 'decimal') {
      if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === "=") {
        e.preventDefault();
      }
    }
  }

  return (
    <StyledTextField
      onChange={onTextChange}
      value={value}
      size="small"
      variant="outlined"
      placeholder=""
      type={type}
      onKeyDown={onTextKeyDown}
      InputProps={{ style: { color: theme.palette.primary.main } }} // Set the text color to primary
    />
  );
};

export default TableTextField;
