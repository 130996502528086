import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { Link, useNavigate } from 'react-router-dom';

import CustomButton from '../../../components/button';
import SearchBar from '../../../components/SearchBar/searchBar';
import CustomTable from '../../../components/CustomTable/customTable';
import { ACTION_STATUS, USER_TABLE_HEADER_COLUMNS } from '../../../utils/constants';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import { AppColors } from '../../../utils/colors';
import CustomAutocomplete from '../../../components/AutoComplete/autoComplete';
import { isArrayEmpty, isEmpty, isNull, isSuperAdmin, isUndefined } from '../../../utils/utils';
import Loader from '../../../components/Loader/loader';
import { deleteUser, getOrgList, getUserList } from '../../../utils/services';
import { STORAGE_KEYS, getDataLocally } from '../../../utils/localStorage';
import DecisionModal from '../../../components/decisionModal';
import DeleteIcon from '../../../assets/images/deleteIcon.svg';
import ResponseModal from '../../../components/responseModal';

const UserList = () => {
  
  const navigate = useNavigate();
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [searchUserName, setSearchUserName] = useState("");
  const [userListData, setUserListData] = useState([]);
  const [orgListData, setOrgListData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [showLoader, setLoader] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [selectedUserToDelete, setSelectedUserToDelete] = useState(null);
  const [successResponse, setSuccessResponse] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [page] = useState(1);
  const getLoggedInOrganization = getDataLocally(STORAGE_KEYS.LOGIN_ORGANIZATION);

  const handleSearch = (e) => { setSearchUserName(e.target.value) };
  const handleSuccessModal = () => {setShowModal(!showModal)};
  
  const toggleDeleteUserModal = (user) => {
    setSelectedUserToDelete(user);
    setShowDeleteUserModal(!showDeleteUserModal);
  };

  async function handleDeleteUser (user) {
    var form = new FormData();
    form.append('user_id', user.user_id);
    form.append('is_deleted', true);
    await deleteUser(form).then((res)=>{
        if (!isNull(res.body)&&!isUndefined(user) && !isNull(user)) {
            // var filteredUserList = userListData.filter((userItem) => userItem.user_id !== user.user_id)
            // setUserListData(filteredUserList);
            toggleDeleteUserModal();
            setShowModal(true);
            const response = {
              statusCode: res.statusCode,
              message: res.statusMessage
            }
          setSuccessResponse(response);
          getUserListData(page);
        }
    });
  }

  const onOrganizationSelection = (event, newValue) => {
    setSelectedOrganization(newValue);
    getUserListData(page, newValue);
  };

  const navigateToUserCreationPage = () => { navigate("/dashboard/users/create-user") }

  const renderUserList = (user) => {
    return (
      <TableRow
        sx={{ '&:nth-of-type(even), &:hover': { backgroundColor: AppColors.grey.main } }}
        key={user.user_id}
      >
        <TableCell >{user.first_name}</TableCell>
        <TableCell >{user.last_name}</TableCell>
        <TableCell >{user.email_id}</TableCell>
        <TableCell >{user.user_phone}</TableCell>
        <TableCell >
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            spacing={2}
            className="adminTableActions"
          >
            <IconButton aria-label="visibility" >
              <Link to={"/dashboard/users/create-user" } state={{ userData: user, displayStatus: ACTION_STATUS.VIEW }} >
              < Visibility sx={{ color: AppColors.grey.dark }} />
              </Link>
            </IconButton>
            <IconButton aria-label="edit">
              <Link to={"/dashboard/users/create-user"} state={{ userData: user, displayStatus: ACTION_STATUS.EDIT }}>
                <Edit sx={{ color: AppColors.appColor.main }} />
              </Link>
            </IconButton>
            {!isSuperAdmin() &&
              <IconButton aria-label="delete">
                <Delete sx={{ color: AppColors.error.main }} onClick={()=>toggleDeleteUserModal(user)}/>
              </IconButton>
            }
          </Stack>
        </TableCell>
      </TableRow>
    )
  }

  async function getOrgListData(pageNo) {
    let params = {
      org_id: getLoggedInOrganization.org_id,
      display_type: isSuperAdmin() ? 0 : 1,
      isMaster: true,
      page_no: pageNo
    }
    await getOrgList(params).then((res) => {
      if (!isNull(res.body)) {
        setOrgListData(res.body.data);
      }
    })
  }

  function getOrgId(org) {
    if (!isUndefined(org) && !isNull(org)) {
      return org.org_id;
    }
    return getLoggedInOrganization.org_id;
  }

  async function getUserListData(pageNo, org) {
    console.log(org)
    setLoader(true)
    let param = {
      org_id: getOrgId(org),
      role_id: getLoggedInOrganization.role_id,
      page_no: pageNo
    }
    await getUserList(param).then((res) => {
      if (!isNull(res.body)) {
        setUserListData(res.body.data)
        setDataCount(res.body.dataCount)
      }
      setLoader(false)
    }).catch(() => setLoader(false))
  };

  const onPageChange = async (pageNo) => {
    await getUserListData(pageNo, selectedOrganization)
  };

  useEffect(() => {
    if (isSuperAdmin()) {
      getOrgListData(page);
    } else {
      getUserListData(page);
    }
  }, [page]);

  
  const filteredUserList = isEmpty(searchUserName) ? !isArrayEmpty(userListData) && userListData : !isArrayEmpty(userListData) && userListData.filter((user) => (user.first_name.toLowerCase().includes(searchUserName.toLowerCase()) || user.last_name.toLowerCase().includes(searchUserName.toLowerCase())));


  return (
    <>
      <Loader show={showLoader} />
      <DecisionModal
        title={"Delete User"}
        icon={DeleteIcon}
        showModal={showDeleteUserModal}
        onClick={() => handleDeleteUser(selectedUserToDelete)}
        message={"Are you sure you want to delete the user?"}
        handleModalState={toggleDeleteUserModal}
        showButtons={true}
      />
      {showModal === true &&
        <ResponseModal
          showModal={showModal}
          message={`${successResponse.message} successfully`}
          statusCode={successResponse.statusCode}
          onClick={handleSuccessModal}
        />
      }
      <Box component="div" display="flex" justifyContent="flex-end" sx={{mt:1, mr:4}}>
          <CustomButton
            name="Create User"
            color="primary"
            icon={<AddCircleOutlinedIcon />}
            className="user-creation-button"
            onClick={navigateToUserCreationPage}
          />
      </Box>
      <Box sx={{ p: 4, pt:2 }}>
        <Grid container >
          <Grid item md={9}>
            <SearchBar
              variant="outlined"
              placeholder={"Search here..."}
              iconPosition="start"
            value={searchUserName}
            onChange={handleSearch}
            />
          </Grid>
          <Grid item md={3} className='org-list-dropdown'>
            {
            isSuperAdmin() &&
            <CustomAutocomplete
              disableClearable={true}
              options={orgListData}
              getOptionLabel={(option) => option.org_name}
              value={selectedOrganization}
              onChange={onOrganizationSelection}
              fullWidth={true}
              customLabelName={"user-label-name"}
              placeholder={"select a organization"}
            />
            }
          </Grid>
        </Grid>
        <Box component="div" sx={{ mt: 5 }}>
          {isNull(selectedOrganization) && isSuperAdmin() ? (
            <Typography variant='body1' align="center" sx={{ mb: 3 }}>User data is available upon selecting an organization</Typography>
          ) :
            !isArrayEmpty(userListData) ?
              <CustomTable
                tdata={filteredUserList}
                headerColumns={USER_TABLE_HEADER_COLUMNS}
                render={renderUserList}
                pageChangeCallBack={onPageChange}
                dataCount={dataCount}
              />
              : (
                <Typography variant='body1' align="center" sx={{ mb: 3 }}>There are no users data available</Typography>
              )}
        </Box>
      </Box>

    </>
  )
}

export default UserList;
