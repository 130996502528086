import { legacy_createStore as createStore } from "redux"
import { REDUX_ACTIONS } from "../utils/constants"

const initialState = {
    axiosResponse : null,
    onDownloadRequest : [],
}

function handleState(state = initialState, action) {
    switch (action.type) {
        case REDUX_ACTIONS.RESPONSE_HANDLER:
            return {
                ...state,
                axiosResponse: action.axiosResponse
            }
        case REDUX_ACTIONS.DOWNLOAD_REQUEST:
            return {
                ...state,
                onDownloadRequest: action.onDownloadRequest
            }
        default:
            return { ...state }
    }
}

const store = createStore(handleState)

export default store;