import React, { useState } from "react";
import PageContentHeader from "../../../components/PageContentHeader/PageContentHeader";
import DateFilterSaveOptions from "../../../components/DateFilterSaveOptions/DateFilterSaveOptions";
import { Container, Stack, Box } from "@mui/material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Button,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { styled } from "@mui/material/";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UploadMultiplePatientsSection from "./sections/UploadMultiplePatientsSection";
import HomeAccordion from "./../../../components/accordions/HomeAccordion";
import AddIndividualPatientDataSection from "./sections/AddIndividualPatientDataSection";

const Home = (props) => {
  const homeAccordionsData = [
    {
      title: "Create Individual Patient Information",
      component: <AddIndividualPatientDataSection />,
    },
    {
      title: "Upload Multiple Patient Information",
      component: <UploadMultiplePatientsSection />,
    },
  ];
  return (
    <>
      {homeAccordionsData.map((data, dataIndex) => (
        <HomeAccordion
          render={data.component}
          title={data.title}
          index={dataIndex}
          key={dataIndex}
        />
      ))}
    </>
  );
};

export default Home;
