import React from 'react';
import { PatternFormat } from 'react-number-format';
import CustomInputLabel from '../InputLabel/customInputLabel';
import { StyledTextField } from '../../utils/constants';

const PhoneNumber = (props) => {
  const { name, id, format, value, onChange, disabled, placeholder, label, customLabelName, maxLength, error, helperText, fullWidth } = props;

  const onHandleChange = (e) => {
    onChange(e.target.value);
  };

  const materialUITextFieldProps = {
    variant: "outlined",
    error: error,
    helperText: helperText,
    fullWidth: fullWidth,
    sx: { mb: 1 }
  };


  return (
    <>
      <CustomInputLabel label={label} customClassName={customLabelName} />
      <PatternFormat
        className='form_input form-control'
        format={format}
        id={id}
        name={name}
        value={value}
        onChange={onHandleChange}
        customInput={StyledTextField}
        maxLength={maxLength}
        disabled={disabled}
        placeholder={placeholder}
        {...materialUITextFieldProps}
      />
    </>
  )
}

export default PhoneNumber;
