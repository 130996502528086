import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { ExpandCircleDown } from "@mui/icons-material";
import { Grid } from "@mui/material";

const HomeAccordion = ({ index, onSelected, title, render }) => {
  const [expanded, setExpanded] = useState(false);
  const [accordionKey] = useState("panel" + index);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion elevation={0} defaultExpanded={index === 0} onChange={handleChange(accordionKey)} key={accordionKey}>
      <AccordionSummary expandIcon={<ExpandCircleDown color="primary" />} aria-controls={accordionKey + "bh-content"} id={accordionKey + "bh-header"} sx={{minHeight : '80px'}}>
        <Grid container>
          <Grid item>
            <Typography variant="p" component="div" color="primary" fontWeight={700}>{title}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{render}</AccordionDetails>
    </Accordion>
  );
};
export default HomeAccordion;
