import React from "react";
import { Button, Typography } from "@mui/material";
import { isUndefined } from "../../utils/utils";
import { AppColors } from "../../utils/colors";

const ToolbarIconButton = (props) => {
  const {
    toolBarStartIcon,
    toolBarEndIcon,
    toolBarTitle,
    disabled,
    height = "50px",
    ...restProps
  } = props;

  const customSx = {
    backgroundColor: AppColors.grey.light,
    color: AppColors.appColor.main,
    height: { height },
    padding: "10px !important",
    transition: "0.2s",
    ":hover": {
      backgroundColor: AppColors.grey.dark,
      color: AppColors.grey.primary,
    },
  };

  if (isUndefined(toolBarTitle) && isUndefined(toolBarEndIcon)) {
    return (
      <Button disableElevation size="small" {...restProps} sx={customSx}  disabled={disabled}>
        {toolBarStartIcon}
      </Button>
    );
  } else {
    return (
      <Button
        variant="contained"
        size="small"
        disableElevation
        startIcon={toolBarStartIcon}
        endIcon={toolBarEndIcon}
        sx={customSx}
        disabled={disabled}
        {...restProps}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "13px",
          }}
        >
          {toolBarTitle}
        </Typography>
      </Button>
    );
  }
};

export default ToolbarIconButton;
