import React from "react";
import { isUndefined } from "../utils/utils";
import { styled } from "styled-components";
import { Box, FormControl, TextField } from "@mui/material";
import CustomInputLabel from "./InputLabel/customInputLabel";
import { AppColors } from "../utils/colors";

const inputSx = {
  borderRadius: 5,
  height: 30,
  outline: 0,
  border: 0,
};

const CustomTextBox = ({
  id,
  type,
  placeholder,
  onChange,
  isRequired,
  value,
  title,
  error,
  helperText,
  disabled,
  maxLength,
  customClassName,
  customLabelName,
  rows,
  multiline,
}) => {
  const onTextChange = (e) => {
    e.preventDefault();
    onChange(e.target.value);
  };

  return (
    <Box marginTop={3} marginBottom={2}>
      {!isUndefined(title) && <CustomInputLabel label={title} customClassName={customLabelName} />}
      <FormControl fullWidth>
        <StyledTextField
          variant="outlined"
          type={type}
          id={id}
          value={value}
          required={isRequired}
          placeholder={!isUndefined(placeholder) ? placeholder : ""}
          inputProps={{
            maxLength: !isUndefined(maxLength) ? maxLength : 50,
          }}
          onChange={onTextChange}
          autoComplete="off"
          error={error}
          helperText={helperText}
          className={customClassName}
          disabled={disabled}
          multiline={multiline}
          rows={rows}
        />
      </FormControl>
    </Box>
  );
};

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    color: "white",
    "& fieldset": {
      borderColor: AppColors.appColor.main,
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: AppColors.appColor.main,
    },
  },
});

export default CustomTextBox;
