import React from "react";
import { Outlet } from "react-router-dom";
import "./style.css";
import AppNavBar from "../../components/navbar";
import Sidebar from "../../components/sidebar/sidebar";

const Dashboard = (props) => {
  return (
    <>
      <AppNavBar />
      <Sidebar>
        <Outlet />
      </Sidebar>
    </>
  );
};

export default Dashboard;
