import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { Link, useNavigate } from 'react-router-dom';

import CustomButton from '../../../components/button';
import SearchBar from '../../../components/SearchBar/searchBar';
import CustomTable from '../../../components/CustomTable/customTable';
import { ACTION_STATUS, ORGANIZATION_TABLE_HEADER_COLUMNS } from '../../../utils/constants';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import { AppColors } from '../../../utils/colors';
import { isArrayEmpty, isEmpty, isNull, isSuperAdmin, isUndefined, truncate } from '../../../utils/utils';
import { deleteOrganization, getOrgList } from '../../../utils/services';
import Loader from '../../../components/Loader/loader';
import { STORAGE_KEYS, getDataLocally } from '../../../utils/localStorage';
import DecisionModal from '../../../components/decisionModal';
import DeleteIcon from '../../../assets/images/deleteIcon.svg';
import ResponseModal from '../../../components/responseModal';


const OrganizationList = () => {

  const navigate = useNavigate();
  const [searchOrgName, setSearchOrgName] = useState("");
  const [organizationListData, setOrganizationListData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [showLoader, setLoader] = useState(false);
  const [showDeleteOrgModal, setShowDeleteOrgModal] = useState(false);
  const [selectedOrgToDelete, setSelectedOrgToDelete] = useState(null);
  const [successResponse, setSuccessResponse] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [page] = useState(1);
  const getLoggedInOrganization = getDataLocally(STORAGE_KEYS.LOGIN_ORGANIZATION);

  const handleSearch = (e) => { setSearchOrgName(e.target.value) };

  const toggleDeleteOrgModal = (org) => {
    setSelectedOrgToDelete(org);
    setShowDeleteOrgModal(!showDeleteOrgModal);
  };

  const handleSuccessModal = () => {setShowModal(!showModal)};

  const navigateToOrgCreationPage = () => { navigate("/dashboard/organization/create-organization") }

  async function handleDeleteOrganization (org) {
    var form = new FormData();
    form.append('org_id', org.org_id);
    form.append('is_deleted', true);
    await deleteOrganization(form).then((res)=>{
        if (!isNull(res.body)&&!isUndefined(org) && !isNull(org)) {
            // var filteredOrgList = organizationListData.filter((orgItem) => orgItem.org_id !== org.org_id)
            // setOrganizationListData(filteredOrgList);
            toggleDeleteOrgModal();
            setShowModal(true);
            const response = {
              statusCode: res.statusCode,
              message: res.statusMessage
            }
          setSuccessResponse(response);
          getOrgListData(page);
        }
    });
  }

  const renderOrganizationList = (organization) => {
    return (
      <TableRow
        sx={{ '&:nth-of-type(even), &:hover': { backgroundColor: AppColors.grey.main } }}
        key={organization.org_id}
      >
        <TableCell >{organization.org_name}</TableCell>
        <TableCell >{organization.org_email}</TableCell>
        <TableCell title={organization.org_description} >{truncate(organization.org_description, 50)}</TableCell>
        <TableCell title={organization.org_address} >{truncate(organization.org_address, 30)}</TableCell>
        <TableCell >{organization.org_contact}</TableCell>
        <TableCell title={organization.org_website}>{truncate(organization.org_website, 30)}</TableCell>
        <TableCell >
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            spacing={2}
            className="adminTableActions"
          >
            <IconButton aria-label="visibility" >
              <Link to={"/dashboard/organization/create-organization" } state={{ orgData: organization, displayStatus: ACTION_STATUS.VIEW }} >
              <Visibility sx={{ color: AppColors.grey.dark }} />
             </Link>
            </IconButton>
            <IconButton aria-label="edit">
            {/* <Link to={"/dashboard/organization/create-organization"} state={{ orgData: organization, displayStatus: ACTION_STATUS.EDIT }}> */}
              {/* <Edit sx={{ color: AppColors.appColor.main }} /> */}
            {/* </Link> */}
            </IconButton>
            {isSuperAdmin() &&
              <IconButton aria-label="delete">
                <Delete sx={{ color: AppColors.error.main }} onClick={()=>toggleDeleteOrgModal(organization)}/>
              </IconButton>
            }
          </Stack>
        </TableCell>
      </TableRow>
    )
  }

  async function getOrgListData(pageNo) {
    setLoader(true);
    let params = {
      org_id: getLoggedInOrganization.org_id,
      display_type: isSuperAdmin() ? 0 : 1,
      isMaster: false,
      page_no: pageNo
    }
    await getOrgList(params).then((res) => {
      if (!isNull(res.body)) {
        setDataCount(res.body.dataCount);
        setOrganizationListData(res.body.data);
      }
      setLoader(false);
    }).catch(() => setLoader(false))
  }

  const onPageChange = async (pageNo) => {
    await getOrgListData(pageNo)
  };

  useEffect(() => {
    getOrgListData(page); 
  },[page])

  const filteredOrgList = isEmpty(searchOrgName) ? !isArrayEmpty(organizationListData) && organizationListData : !isArrayEmpty(organizationListData) && organizationListData.filter((org) => (org.org_name.toLowerCase().includes(searchOrgName.toLowerCase())));
  
  return (
    <>
      <Loader show={showLoader} />
      <DecisionModal
        title={"Delete Organization"}
        icon={DeleteIcon}
        showModal={showDeleteOrgModal}
        onClick={() => handleDeleteOrganization(selectedOrgToDelete)}
        message={"Are you sure you want to delete the organization?"}
        handleModalState={toggleDeleteOrgModal}
        showButtons={true}
      />
      {showModal === true &&
        <ResponseModal
          showModal={showModal}
          message={`${successResponse.message} successfully`}
          statusCode={successResponse.statusCode}
          onClick={handleSuccessModal}
        />
      }
      <Box sx={{ p: 4, pt:2 }}>
        <Grid container >
          <Grid item md={10}>
            <SearchBar
              variant="outlined"
              placeholder={"Search here..."}
              iconPosition="start"
            value={searchOrgName}
            onChange={handleSearch}
            />
          </Grid>
          <Grid item md={2}>
          {/* <Box component="div" display="flex" justifyContent="flex-end" sx={{mt:1, mr:4}}> */}
          <CustomButton
            name="Create Organization"
            color="primary"
            isFullWidth={true}
            icon={<AddCircleOutlinedIcon />}
            className="user-creation-button"
            onClick={navigateToOrgCreationPage}
          />
      {/* </Box> */}
          </Grid>
        </Grid>
        <Box component="div">
          {!isArrayEmpty(organizationListData) ?
            (<CustomTable
              tdata={filteredOrgList}
              headerColumns={ORGANIZATION_TABLE_HEADER_COLUMNS}
              render={renderOrganizationList}
              pageChangeCallBack={onPageChange}
              dataCount={dataCount}
            />) : (
              <Typography variant='body1' align="center" sx={{ mb: 3 }}>There are no  organization users data available</Typography>
            )}
        </Box>
      </Box>

    </>
  )
}

export default OrganizationList;