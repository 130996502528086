import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { Box, Button, Grid, Popover, Stack, Checkbox, Typography } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import RangeSlider from "./FilterRangeSlider";
import { DateRangePicker } from "./DatePickers";
import ToolBarIconButton from "../toolbarIconButton/toolbarIconButton";
import { HistoryFilterContext } from "../../modules/dashboard/historyFilterContext";
import { AppColors } from "../../utils/colors";
import { convertJsonToExcel, exclusiveOR, getObjectKeyValues, isArrayEmpty, isNull, isObjectEmpty } from "../../utils/utils";
import { useSelector } from "react-redux";
import { getRangeFieldMaxMinValues, getRecords } from "../../utils/services";
import DecisionModal from "../decisionModal";
import Loader from "../Loader/loader";

const DateFilterSaveOptions = () => {
  const ButtonHeight = 50;
  const recordsToBeDownloaded = useSelector((state)=>state.onDownloadRequest)
  const [showDownloadPromptModal , setShowDownloadPromptModal] = useState(false)
  const [showLoader, setLoader] = useState(false);
  const [rangeFieldData, setRangeFieldData] = useState([]);

  const toggleDownloadPromptModal = () => setShowDownloadPromptModal(!showDownloadPromptModal)

  function onDownload(){
    if(!isArrayEmpty(recordsToBeDownloaded)){ //Checking if any records are selected to download else download all the records
      convertJsonToExcel(recordsToBeDownloaded)
    }else{
      toggleDownloadPromptModal()
    }
  }

  async function getMasterRecords(){
    setLoader(true)
    let param = {isMaster : true}
    await getRecords(param).then((res) =>{
      convertJsonToExcel(res.body.data)
      setLoader(false)
      toggleDownloadPromptModal()
    }).catch(()=>setLoader(false))
  }  

  useEffect(() => {
    getRangeFieldMinMaxValuesInFilter();
  }, []);

  async function getRangeFieldMinMaxValuesInFilter() {
    setLoader(true);
    await getRangeFieldMaxMinValues().then((res) => {
      if (!isNull(res.body.data)) {
        setRangeFieldData(res.body.data);
        setLoader(false);
      }
    }).then(() => { setLoader(false); })
  }


  return (
    <>
      <Loader show={showLoader}/>
      <DecisionModal
        showModal={showDownloadPromptModal}
        title={'Download'}
        message={'No Records are selected, Do you want to download all the records in the system ?'}
        showButtons={true}
        onClick={()=>getMasterRecords()}
        handleModalState={toggleDownloadPromptModal}
      />
      <Stack direction="row" spacing={1} alignItems="flex-start">
        <DateRangePicker height={ButtonHeight} />
        <FilterPopover height={ButtonHeight} rangeFieldData={rangeFieldData}/>
        <ToolBarIconButton
          height={ButtonHeight}
          toolBarStartIcon={<SaveAltIcon />}
          onClick={()=>onDownload()}
        />
      </Stack>
    </>
  );
};

const FilterPopover = ({ height = 35 , rangeFieldData}) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <ToolBarIconButton
        height={height}
        toolBarStartIcon={<FilterAltIcon />}
        onClick={handleClick}
        disabled={isArrayEmpty(rangeFieldData)}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <FilterCard onClose={handleClose} rangeFieldDataForFilter={rangeFieldData} />
      </Popover>
    </>
  );
};

const FilterCard = ({ onClose, rangeFieldDataForFilter }) => {
  
  const { filters, setFilters } = useContext(HistoryFilterContext)
  const [localFilters, setLocalFilters] = useState(filters)

  // Soon: use local filters to determine initial value of showing indiv
  const [showingIndiv, setShowingIndiv] = useState(filters['isIndividualPatients'] === "true");
  const [showingMult, setShowingMult] = useState(filters['isMultiplePatients'] === "true")
  const desiredRangeFields = getObjectKeyValues(rangeFieldDataForFilter);

  const handleResetFilters = async () => {
    handleSetFilters(true);
  }

  const handleSetFilters = async (reset = false) => {
    let newFilters = {}
    if (reset) {
      setLocalFilters(newFilters);
      setFilters(newFilters);
    } else {
      // If exclusively individual or multiple filters checked, exclusively set those parameters
      if (exclusiveOR(showingMult, showingIndiv)) {
        if (showingMult) {

          newFilters = { ...localFilters, 'isMultiplePatients': "true", 'isIndividualPatients': 'false' }
        } else {
          newFilters = { ...localFilters, 'isIndividualPatients': "true", 'isMultiplePatients': 'false' }
        }

      } else {
        newFilters = { ...localFilters }
        // If both checked or niether checked, just remove the filter from the search
        delete newFilters['isMultiplePatients']
        delete newFilters['isIndividualPatients']
      }
      setLocalFilters(newFilters)
      setFilters(newFilters);
    }
    onClose();
  };

 
  // Dotted Border also used outside customGridSx, so pulled out of it
  const dottedBorder = `1px dotted ${AppColors.grey.shade}`;
  const CustomGridSx = {
    borderTop: dottedBorder,
    borderBottom: dottedBorder,
    margin: "2px 0px",
  };

  // Finds index of range filter item in the filters,
  // and decides which borders in the UI shoud be dashed or not
  // to precisely match the figma design
  const borderIsDashed = (index, side) => {
    if (side.toLowerCase().localeCompare("left") === 0) {
      // The left border is dashed if the item is on the left side of the card
      const isLeftHandSide = index % 2 === 0
      return isLeftHandSide;
    } else if (side.toLowerCase().localeCompare("right") === 0) {
      // The right border is dashed if the item is on the right side of the card
      // OR if it is the last item in the grid
      const isRightHandSide = index % 2 === 1
      const isLastItem = index === desiredRangeFields.length - 1
      return isRightHandSide || isLastItem;
    } else {
      return false;
    }
  };

  const handleCheckboxChecked = (event) => {
    // Goal: if indiv checkbox and mult-checkbox both checked, then show everything
    // If only indiv checked, show only indiv
    // If only mult checked, show only mult
    if (event.target.name === 'indiv-checkbox') {
      setShowingIndiv(event.target.checked)
    } else if (event.target.name === 'mult-checkbox') {
      setShowingMult(event.target.checked)
    }

  }

  
  const updateRangeFieldData = (rangeFieldData) => {
    // Check if localFilters is not empty
    if (!isObjectEmpty(localFilters)) {
      // Update rangeFieldData based on localFilters
      getObjectKeyValues(localFilters).forEach((key) => { 
        let currentValue = localFilters[key].split(",");
        rangeFieldData[key] = [currentValue[1], currentValue[0]];
      })
    }
    return rangeFieldData;
  }
  
    
  return (
    <Box sx={{ width: "50vw" }}>
      {/* <Loader show={showLoader}/> */}
      <Stack direction="row" alignItems="center" sx={{ backgroundColor: AppColors.grey.dark, color: "white", padding: "12px", }}>
        <FilterAltIcon /> Filter
      </Stack>
      <Box sx={{ flexGrow: 1, overflowY: "auto", boxSizing: "border-box", padding: "10px", maxHeight: '50vh' }} >
        <Grid container sx={{}} style={{ fontSize: "0.9rem" }}>
          <Grid item xs={6} sx={{ ...CustomGridSx, borderLeft: dottedBorder, }} >
            <Checkbox name="indiv-checkbox" onChange={handleCheckboxChecked} checked={showingIndiv} />
            <Typography variant="p" fontWeight={600}>Individually Uploaded Data</Typography>
          </Grid>
          <Grid item xs={6} sx={{ ...CustomGridSx, borderRight: dottedBorder, mb:isArrayEmpty(desiredRangeFields) && 15 }}>
            <Checkbox name="mult-checkbox" onChange={handleCheckboxChecked} checked={showingMult} />
            <Typography variant="p" fontWeight={600}>Multiple Uploaded Data</Typography>
          </Grid>
          {!isArrayEmpty(desiredRangeFields) && desiredRangeFields.map((data, index) => {
            return (
              <Grid key={index + 1} item xs={6}
                sx={{
                  display: "flex", flexDirection: "column",
                  justifyContent: "flex-end",
                  ...CustomGridSx,
                  borderLeft: borderIsDashed(index, "left") ? dottedBorder : "0",
                  borderRight: borderIsDashed(index, "right") ? dottedBorder : "0",
                  padding: 2
                }}
              >
                <RangeSlider recordKey={data} localFilters={localFilters} setLocalFilters={setLocalFilters} maxMinRangeValues={updateRangeFieldData({...rangeFieldDataForFilter})} maxMinSliderValues={rangeFieldDataForFilter} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        sx={{
          backgroundColor: AppColors.grey.main,
          position: "relative",
          bottom: "0px",
          padding: "5px",
          width: "100%",
          boxShadow: "0px -2px 6px rgba(0, 0, 0, 0.4)", // Add the upper shadow here
        }}
      >
        <Button variant="outlined" onClick={onClose}>Cancel</Button>
        {/* Not sure why, but when I wasn't using () => {} notation it wasn't working */}
        <Button variant="outlined" onClick={() => handleResetFilters()}>Reset</Button>
        <Button variant="contained" onClick={() => handleSetFilters()}>Apply</Button>
      </Stack>
    </Box>
  );
};

export default DateFilterSaveOptions;
